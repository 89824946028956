<!-- <div class="comments">
  <div class="comment new">
    <div class="user">
      <c-user-icon *ngIf="authUser | async as user" [user]="user"></c-user-icon>
    </div>
  </div>
</div> -->
<form [formGroup]="addCommentForm" (ngSubmit)="onSubmit()" class="comment">
  <p-editor formControlName="comment"></p-editor>
  <button type="submit" [disabled]="!addCommentForm.get('comment')?.value">
    Send <i class="pi pi-send"></i>
  </button>
</form>
<div class="history">
  <div *ngFor="let comment of comments; index as i" class="entry-container">
    <div class="user">
      <c-user-icon *ngIf="comment.user$" [user]="comment.user$"></c-user-icon>
    </div>
    <div class="entry">
      <div class="entry-header">
        {{comment.user$?.displayName}} <span class="timestamp">at {{comment.createdAt | date:'medium'}}</span>
      </div>
      <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(comment.message)" class="entry-body"></div>
    </div>
  </div>
</div>
