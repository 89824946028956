import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, using, filter } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { Environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private socket: Socket;
  private registrations: {[key: string]: {
    [event: string]: {
      subject: ReplaySubject<any>;
      observable: Observable<any>;
    }
  }} = {}

  constructor(
    private environment: Environment,
  ) {
    this.socket = io(this.environment.socketUrl, {
      path: this.environment.socketPath, 
      transports: ['websocket'], 
    });

    this.socket.on('connect', () => {
      for (const registration of Object.keys(this.registrations)) {
        const [type, id] = registration.split(':')
        this.socket.emit(`${type}:register`, {id})
      }
    })
  }

  public observe<T>(type: string, id: string, event: string): Observable<T> {
    const key = `${type}:${id}`

    if (!this.registrations[key]) {
      this.registrations[key] = {}
    }

    if (this.registrations[key][event]) {
      return this.registrations[key][event].observable
    }

    const subject = new ReplaySubject<T>(1);
    const observable = using(
      () => {
        return {
          unsubscribe: () => {
            if (!subject.observed) {
              delete this.registrations[key][event];

              if (Object.keys(this.registrations[key]).length === 0) {
                delete this.registrations[key]
              }

              subject.complete();
              this.socket.off(event)
              this.socket.emit(`${type}:unregister`, { id });
            }
          },
        };
      },
      () => {
        return subject;
      }
    );

    this.registrations[key][event] = {
      subject: subject,
      observable: observable,
    };

    this.socket.on(event, (args) => {
      subject.next(args)
    })

    this.socket.emit(`${type}:register`, { id });

    return observable
  }

  public filtered<T>(type: string, id: string, event: string, filterFunction: (data: T) => boolean): Observable<T> {
    return this.observe<T>(type, id, event).pipe(filter(filterFunction))
  }
}
