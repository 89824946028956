<ul class="nav navbar-nav">
  <li>
    <a [routerLinkActive]="'active'" [routerLink]="'/agencies'">Agencies</a>
  </li>
  <li>
    <a [routerLinkActive]="'active'" [routerLink]="'/orders'">Orders</a>
  </li>
  <li>
    <a [routerLinkActive]="'active'" [routerLink]="'/jobs'">Jobs</a>
  </li>
  <li>
    <a [routerLinkActive]="'active'" [routerLink]="'/tasks'">Tasks</a>
  </li>

  <!-- <li>
    <a *ngIf="!isLoggedIn()" (click)="login()">Login</a>
    <a  *ngIf="isLoggedIn()" [routerLinkActive]="'active'" [routerLink]="'profile'">{{userProfile.email}}</a>
  </li>

  <li *ngIf="!isLoggedIn()">
    <a  [routerLinkActive]="'active'" [routerLink]="'register'">Register</a>
  </li> -->

  <!-- <li>
    <a *ngIf="!(authUser | async)" (click)="login()">Login</a>
    <a  *ngIf="(authUser | async)" [routerLinkActive]="'active'" [routerLink]="'/profile'">{{displayName}}</a>
  </li>

  <li>
    <a *ngIf="!(authUser | async)" [routerLinkActive]="'active'" (click)="register()">Register</a>
    <a *ngIf="(authUser | async)" (click)="logout()">Logout</a>
  </li> -->

  <!-- <button class="btn btn-sm btn-default" (click)="login()" *ngIf="(isAuthenticated$ | async) === false">Log in</button>
  <span *ngIf="isAuthenticated$ | async" id="email">{{email}}</span>
  <button *ngIf="isAuthenticated$ | async" href="#" (click)="logout()" class="btn btn-link">(log out)</button> -->
</ul>
