import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConditionComponent } from './condition.component';
import { PipesModule } from '../../pipes/pipes.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';
import { ChipsModule } from 'primeng/chips';
import { MenuModule } from 'primeng/menu';

@NgModule({
  declarations: [
    ConditionComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
    MultiSelectModule,
    MenuModule,
    FormsModule,
    ChipsModule,
  ],
  exports: [
    ConditionComponent
  ]
})
export class ConditionModule { }
