import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as crypto from 'crypto-js';
import { User } from 'projects/api/src/api';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'c-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss']
})
export class UserIconComponent implements OnChanges {

  @Input()
  user: User | null = null

  initials: string = ''
  
  @HostBinding('style.backgroundColor')
  color: string = 'rgb(0, 0, 0)'

  @HostBinding('class')
  @Input()
  size: 'small' | 'normal' = 'normal'

  constructor(
    private utilsService: UtilsService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.user) {
      this.initials = this.user.displayName.split(' ').slice(0, 2).map(n => n.slice(0, 1)).join('')
      const hash = crypto.MD5(this.user._id).toString()
      this.color = this.utilsService.pastelColor(hash, 'string')
    } else {
      this.initials = '??'
      const hash = crypto.MD5('??').toString()
      this.color = this.utilsService.pastelColor(hash, 'string')
    }
  }

}
