<div class="checkbox">
  <input type="checkbox" [ngModel]="selected" (ngModelChange)="selected = $event; selectedChange.emit(selected)" />
</div>
<div class="user">
  <div class="icon notrelevant" *ngIf="!subtask.assignee$ && !agencySubtask && !contractSubtask" pTooltip="Not relevant for your agency">
    
  </div>
  <div class="icon unassigned" *ngIf="!subtask.assignee$ && (contractSubtask || agencySubtask && !subtask.contract$)" pTooltip="Unassigned">
    <i class="pi pi-question"></i>
  </div>
  <div class="icon contract" *ngIf="!subtask.assignee$ && agencySubtask && subtask.contract$" [pTooltip]="'Via contract ' + subtask.contract$.name">
    <i class="pi pi-file-export"></i>
  </div>
  <c-user-icon *ngIf="subtask.assignee$" [user]="subtask.assignee$" size="small" [pTooltip]="'Assigned to ' + subtask.assignee$.displayName"></c-user-icon>
</div>

<div class="details" (click)="open = !open">
  <div class="name">
    <span>
      <span [innerHTML]="subtask | subtask: 'Subtask'"></span>
  
      <span *ngIf="subtask.state !== 'InProgress'" class="subtask-state" [pTooltip]="subtask.state">
        <i *ngIf="subtask.state === 'Done'" class="pi pi-check"></i>
        <i *ngIf="subtask.state === 'New'" class="pi pi-star"></i>
      </span>

      <span *ngIf="subtask.state === 'InProgress'" class="in-progress" pTooltip="In Progress">
        <i class="pi pi-chevron-right"></i>
        <i class="pi pi-chevron-right"></i>
        <i class="pi pi-chevron-right"></i>
      </span>
    </span>

    <span *ngIf="subtask.state !== 'Done' && ownSubtask" class="subtask-tag todo">Your Task</span>

  </div>
  <div class="info" *ngIf="open">
    Created at {{ subtask.createdAt | date:'medium' }}
    <span *ngIf="!subtask.generated"> | Created By {{subtask.createdBy$?.displayName}}</span>
    <span *ngIf="subtask.assignee$"> | Assigned to {{subtask.assignee$.displayName}}</span>
    <span *ngIf="subtask.contract$"> | Via contract {{subtask.contract$.name}}</span>
    <span *ngIf="subtaskClass === 'done'"> | Resolved at {{ resolvedAt | date:'medium' }}</span>
    <span class="subtask-tag generated" *ngIf="subtask.generated" pTooltip="This subtask has been auto generated">Generated</span>
    <!-- <span class="subtask-tag contract" *ngIf="subtask.contract" pTooltip="This subtask is assigned to you">Via&nbsp;Contract</span> -->
  </div>
  <div class="description" *ngIf="open">
    <span *ngIf="assetTask">
      <span *ngIf="subtask | as:'Subtask':'CreateAssetVersion' as s">
        <span *ngIf="s.context.assetVersionName == 'latest'">Download the asset hull and create the first version of the {{assetTask.asset$?.assetType}} {{assetTask.asset$?.name}}.</span>
        <span *ngIf="s.context.assetVersionName != 'latest'">Download the latest version of the {{assetTask.asset$?.assetType}} {{assetTask.asset$?.name}} and create a new version {{s.context.assetVersionName}}.</span>
        It is used by {{s.numberOfOrderLines}} order lines.
      </span>
      <span *ngIf="subtask | as:'Subtask':'CreateVariantSet' as s">
        <span>Create a new variant set {{s.context.variantSet}} within the {{assetTask.asset$?.assetType}} {{assetTask.asset$?.name}}. It is used by {{s.numberOfOrderLines}} order lines.</span>
      </span>
      <span *ngIf="subtask | as:'Subtask':'CreateVariantValue' as s">
        <span>Create a new variant value {{s.context.variantValue}} for the variant set {{s.context.variantSet}} within the {{assetTask.asset$?.assetType}} {{assetTask.asset$?.name}}. It is used by {{s.numberOfOrderLines}} order lines.</span>
      </span>      
      <span *ngIf="subtask | as:'Subtask':'CreateCamera' as s">
        <span>Create a new camera {{s.context.camera}} within the {{assetTask.asset$?.assetType}} {{assetTask.asset$?.name}}. It is used by {{s.numberOfOrderLines}} order lines.</span>
      </span>
    </span>      
    <span *ngIf="subtask | as:'Subtask':'Manual' as s">
      <span>{{s.description}}</span>
    </span>
  </div>

  <div class="button-overlay" [class.show]="menuIsOpen" *ngIf="agencySubtask || contractSubtask" (click)="$event.stopPropagation()">
    <button (click)="assignVisible = true">
      <i class="pi pi-user"></i> Assign
    </button>
    <button *ngIf="subtaskClass == 'new'" (click)="setState('InProgress')">
      <i class="pi pi-play"></i> In Progress
    </button>
    <button *ngIf="subtaskClass == 'inprogress'" (click)="setState('New')">
      <i class="pi pi-stop"></i> Stop
    </button>
    <button *ngIf="subtaskClass == 'inprogress' && !subtask.generated && (subtask.state == 'New' || subtask.state == 'InProgress')" (click)="setState('Done')">
      <i class="pi pi-check"></i> Done
    </button>
    <button *ngIf="menuItems.length > 0" class="more" (click)="menu.toggle($event)">
      <i class="pi pi-ellipsis-v"></i>
    </button>
  </div>
</div>

<p-menu #menu [popup]="true" [model]="menuItems" (onShow)="menuIsOpen = true" (onHide)="menuIsOpen = false" appendTo="body"></p-menu>

<p-sidebar [(visible)]="assignVisible" position="right">
  <ng-template pTemplate="header">Assign</ng-template>
  <c-subtask-assign *ngIf="assignVisible" [task]="task" [subtasks]="[subtask]" (changed)="changed.emit()"></c-subtask-assign>
</p-sidebar>

<p-sidebar [(visible)]="downloadVisible" position="right">
  <ng-template pTemplate="header">Download asset</ng-template>
  
  <div class="input">
    <label>CLI Command</label>
    <input type="text" readonly [value]="downloadCommand" />
  </div>
</p-sidebar>