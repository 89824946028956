import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadProgressComponent } from './upload-progress.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { NgxFilesizeModule } from 'ngx-filesize';


@NgModule({
  declarations: [
    UploadProgressComponent
  ],
  imports: [
    CommonModule,
    ProgressBarModule,
    NgxFilesizeModule,
  ],
  exports: [
    UploadProgressComponent
  ]
})
export class UploadProgressModule { }
