import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';
import { HasRightDirective } from './has-right/has-right.directive';
import { AutoFocusDirective } from "./auto-focus/auto-focus.directive";

const directives = [
  HasRightDirective,
  AutoFocusDirective
];

@NgModule({
  declarations: [...directives],
  imports: [CommonModule, SkeletonModule, ButtonModule],
  exports: [...directives],
})
export class DirectivesModule {}
