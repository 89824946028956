import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { DragDropModule } from 'primeng/dragdrop';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { SidebarModule } from 'primeng/sidebar';
import { PipesModule } from '../../pipes/pipes.module';
import { SearchBoxModule } from '../search-box/search-box.module';
import { ActionsColumnComponent } from './columns/actions-column.component';
import { DateColumnComponent } from './columns/date-column.component';
import { StringColumnComponent } from './columns/string-column.component';
import { UserColumnComponent } from './columns/user-column.component';
import { CmpHostDirective, TableComponent, } from './table.component';
import { NumberColumnComponent } from './columns/number-column.component';
import { NgxFilesizeModule } from 'ngx-filesize';
import { FileSizeColumnComponent } from './columns/filesize-column.component';
import { TableRowComponent, TableSubrowComponent } from './table-row.component';
import { TileComponent } from './tile.component';
import { CheckboxModule } from 'primeng/checkbox'
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox'
import { MenuModule } from 'primeng/menu';
import { DropdownColumnComponent } from './columns/dropdown-column.component';
import { DirectivesModule } from "../../directives/directives.module";
import { SubtaskColumnComponent } from './columns/subtask-column.component';
import { ConditionModule } from '../condition/condition.module';

@NgModule({
  declarations: [
    TableComponent,
    TableRowComponent,
    TableSubrowComponent,
    StringColumnComponent,
    NumberColumnComponent,
    DateColumnComponent,
    UserColumnComponent,
    FileSizeColumnComponent,
    ActionsColumnComponent,
    CmpHostDirective,
    TileComponent,
    DropdownColumnComponent,
    SubtaskColumnComponent,
  ],
  imports: [
    SearchBoxModule,
    CommonModule,
    ButtonModule,
    PipesModule,
    MultiSelectModule,
    SidebarModule,
    ChipModule,
    ChipsModule,
    DragDropModule,
    PaginatorModule,
    RouterModule,
    FormsModule,
    NgxFilesizeModule,
    CheckboxModule,
    TriStateCheckboxModule,
    MenuModule,
    DirectivesModule,
    ConditionModule
  ],
  exports: [
    TableComponent
  ],
  bootstrap: [TableComponent],
})
export class TableModule { }
