<div class="top">
  <h1>
    <span>{{ header }}</span>
    <span class="subheader"> {{ subheader }}</span>

    <div *ngIf="tags" class="tags">
      <span class="tag primary" *ngFor="let tag of tags; let i = index">
        {{tag}}
        <span class="delete-icon" (click)="deleteTag(i)">
          <i class="pi pi-times"></i>
        </span>
      </span>
  
      <span  class="tag primary add" (click)="showAddTagInput()" [pTooltip]="showAddSign ? 'Add Tag' : ''">
        <i *ngIf="showAddSign" class="pi pi-plus"></i>
        <input *ngIf="!showAddSign" #addTagInput [(ngModel)]="addTagValue" (blur)="blurAddTagInput()" (keyup.enter)="keyupEnterAddTagInput()" />
      </span>
    </div>

  </h1>
  <div class="actions">
    <ng-content select="[actions]"></ng-content>
  </div>
</div>
<div class="bottom">
  <div *ngIf="breadcrumbs" class="breadcrumbs">
    <a [routerLink]="'/dashboard'">Dashboard</a>
    <span *ngFor="let breadcrumb of breadcrumbs">
      &raquo;
      <a [routerLink]="breadcrumb.routerLink"  *ngIf="breadcrumb.routerLink">{{breadcrumb.title}}</a>
      <span *ngIf="!breadcrumb.routerLink">{{breadcrumb.title}}</span>
    </span>
  </div>
  <div class="info">
    <ng-content select="[info]"></ng-content>
  </div>
</div>
