import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitByUpperCase',
  pure: true,
})
export class SplitByUpperCasePipe implements PipeTransform {

  transform(value: string): string {
    return value.replace(/([A-Z])/g, ' $1').trim()
  }

}
