import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AgencyService } from '../services/agency.service';
import { AuthService } from './auth.service';

function authAppInitializerFactory(authService: AuthService, agencyService: AgencyService): () => Promise<void> {
  return async () => {
    try {
      await authService.init()
    } catch (err: any) {
      if (err.type === 'invalid_nonce_in_state') {
        location.reload()
      }
    }
  };
}

// We need a factory since localStorage is not available at AOT build time
function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [
    HttpClientModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    AuthService
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: APP_INITIALIZER, useFactory: authAppInitializerFactory, deps: [AuthService, AgencyService], multi: true },
        { provide: OAuthStorage, useFactory: storageFactory },
      ]
    };
  }

}
