import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Upload } from '../../services/upload.service';


@Component({
  selector: 'c-upload-progress',
  templateUrl: './upload-progress.component.html',
  styleUrls: ['./upload-progress.component.scss']
})
export class UploadProgressComponent implements OnInit, OnDestroy {

  @Input() upload!: Upload

  state: 'none' | 'progress' | 'success' | 'error' = 'none'

  subscriptions: Subscription[] = []

  constructor() { }

  ngOnInit(): void {
    this.subscriptions.push(this.upload.progressObservable.subscribe((p) => this.setMode(p)))
  }

  setMode(progress: number | 'Finished' | 'Error' | null) {
    if (progress === 'Finished') {
      this.state = 'success'
    } else if (progress === 'Error') {
      this.state = 'error'
    } else {
      this.state = 'progress'
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  asNumber(progress: number | 'Finished' | 'Error' | null): number {
    if (typeof progress === 'number') return progress
    return -1
  }

}
