import { Injectable } from '@angular/core';
import { Agency, AgencyApiService, AgencyStatistic, AgencyTags, EqualsCondition, GetAgenciesQuery, GetAgencyContractsQuery, GetAgencyInvitationsQuery, GetAgencyUsersQuery, PostAgenciesBody, PostAgencyInvitationDeleteQuery, PostAgencyInvitationsBody, PostAgencyServiceUserBody, PostAgencyServiceUsersBody } from 'projects/api/src/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {

  private _userAgencies: BehaviorSubject<Agency[]> = new BehaviorSubject<Agency[]>([])
  private _selectedAgency: BehaviorSubject<Agency | null> = new BehaviorSubject<Agency | null>(null)
  private _selectedAgencyStatistic: BehaviorSubject<AgencyStatistic | null> = new BehaviorSubject<AgencyStatistic | null>(null)

  private _tags: Promise<AgencyTags> | null = null

  public get userAgency(): Observable<Agency | null> {
    return this._selectedAgency.asObservable()
  }

  public get userAgencies(): Observable<Agency[]> {
    return this._userAgencies.asObservable()
  }

  public get currentUserAgencies(): Agency[] {
    return this._userAgencies.value
  }

  public get currentSelectedAgency(): Agency | null {
    return this._selectedAgency.value
  }

  public get currentSelectedAgencyId(): string | null {
    return this.currentSelectedAgency?._id || null
  }

  public get selectedAgencyStatistic(): Observable<AgencyStatistic | null> {
    return this._selectedAgencyStatistic.asObservable()
  }

  constructor(
    private agencyApiService: AgencyApiService,
    private userService: UserService,
  ) { }

  async initUserAgencies(userId: string) {
    const [ userAgencies ] = await this.userService.getUserAgencies(userId)

    this._userAgencies.next(userAgencies?.items || [])

    const userAgency = this._userAgencies.value.find(a => a._id === localStorage.getItem('activeAgencyId')) || userAgencies?.items[0] || null
    this._selectedAgency.next(userAgency)

    localStorage.setItem('activeAgencyId', userAgency?._id || '')

    if (userAgency) {      
      const [stats] = await this.getStatistic(userAgency._id)
      if (stats) this._selectedAgencyStatistic.next(stats)
    }
  }

  resetUserAgencies() {
    this._userAgencies.next([])
    this._selectedAgency.next(null)
  }

  setActiveUserAgency(agencyId: string, reload: boolean | string = false) {
    const differentAgency = this.currentSelectedAgencyId && agencyId !== this.currentSelectedAgencyId

    this._selectedAgency.next(this._userAgencies.value.find(a => a._id === agencyId) || null )
    localStorage.setItem('activeAgencyId', agencyId);

    if (reload || differentAgency) {
      if (typeof reload === 'string') {
        window.location.href = reload
      } else {
        location.reload()
      }
    }
  }

  public query(query?: GetAgenciesQuery) {
    return this.agencyApiService.getAgencies(query)
  }

  public getStatistic(agencyId: string) {
    return this.agencyApiService.getAgencyStatistic(agencyId)
  }

  public createAgency(body: PostAgenciesBody) {
    return this.agencyApiService.postAgencies(body)
  }

  public getServiceUser(agencyId: string, userId: string) {
    return this.agencyApiService.getAgencyServiceUser(agencyId, userId)
  }

  public getApiKeys(agencyId: string, serviceUserId: string) {
    return this.agencyApiService.getAgencyServiceUserApiKeys(agencyId, serviceUserId)
  }

  public getServiceUsers(agencyId: string) {
    return this.agencyApiService.getAgencyServiceUsers(agencyId)
  }

  public updateServiceUser(agencyId: string, userId: string, body: PostAgencyServiceUserBody) {
    return this.agencyApiService.postAgencyServiceUser(agencyId, userId, body)
  }

  public deleteApiKey(agencyId: string, userId: string, apiKeyMd5: string) {
    return this.agencyApiService.postAgencyServiceUserApiKeyDelete(agencyId, userId, apiKeyMd5)
  }

  public createApiKey(agencyId: string, userId: string) {
    return this.agencyApiService.postAgencyServiceUserApiKeys(agencyId, userId)
  }

  public deleteServiceUser(agencyId: string, userId: string) {
    return this.agencyApiService.postAgencyServiceUserDelete(agencyId, userId)
  }

  public createServiceUser(agencyId: string, body: PostAgencyServiceUsersBody) {
    return this.agencyApiService.postAgencyServiceUsers(agencyId, body)
  }

  public getUsers(query?: GetAgencyUsersQuery) {
    return this.agencyApiService.getAgencyUsers(this.currentSelectedAgencyId!, query)
  }

  public getInvitations(query?: GetAgencyInvitationsQuery) {
    return this.agencyApiService.getAgencyInvitations(this.currentSelectedAgencyId!, query)
  }

  public createInvitation(body: PostAgencyInvitationsBody) {
    return this.agencyApiService.postAgencyInvitations(this.currentSelectedAgencyId!, body)
  }

  public deleteInvitation(invitationId: string, query?: PostAgencyInvitationDeleteQuery) {
    return this.agencyApiService.postAgencyInvitationDelete(this.currentSelectedAgencyId!, invitationId, query)
  }

  public getRoles(type: 'SystemRole' | 'CustomRole') {
    return this.agencyApiService.getAgencyRoles(this.currentSelectedAgencyId!, {
      limit: 99999,
      filter: {
        type: 'Equals',
        path: 'type',
        value: type,
      } as EqualsCondition
    }).then(([roles]) => {
      return roles?.items || []
    })
  }

  public queryContracts(query?: GetAgencyContractsQuery) {
    return this.agencyApiService.getAgencyContracts(this.currentSelectedAgencyId!, query)
  }

  async getTags(): Promise<AgencyTags> {
    const [tags] = await this.agencyApiService.getAgencyTags(this.currentSelectedAgencyId!)

    return tags || {
      agency: this.currentSelectedAgencyId!,
      assetTags: [],
      orderTags: [],
      taskTags: [],
    }
  }

}
