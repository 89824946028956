import { Component, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from "@angular/core"
import { TagsComponent } from "../tags/tags.component"
import { ActionsColumnComponent } from "./columns/actions-column.component"
import { DateColumnComponent } from "./columns/date-column.component"
import { DropdownColumnComponent } from "./columns/dropdown-column.component"
import { FileSizeColumnComponent } from "./columns/filesize-column.component"
import { NumberColumnComponent } from "./columns/number-column.component"
import { StringColumnComponent } from "./columns/string-column.component"
import { SubtaskColumnComponent } from "./columns/subtask-column.component"
import { UserColumnComponent } from "./columns/user-column.component"
import { CmpHostDirective } from "./table.component"
import { TableColumn, TableOptions } from "./table.interfaces"
import { takeUntil } from "rxjs"


@Component({
  selector: '[c-table-subrow]',
  template: `<ng-template cmpHost></ng-template>`,
})
export class TableSubrowComponent<T> {
  @Input() row!: any
  @Input() options!: TableOptions<T>

  @ViewChild(CmpHostDirective) host!: CmpHostDirective

  ngAfterViewInit() {
    const viewContainerRef = this.host.viewContainerRef
    viewContainerRef.clear()
    const val = this.options.subRow!(this.row)
    const componentRef = viewContainerRef.createComponent(val.component)

    for (const key of Object.keys(val.data as any)) {
      (componentRef.instance as any)[key] = (val.data as any)[key]
    }
  }
}

@Component({
  selector: '[c-table-row]',
  template: `
    <td class="selector" *ngIf="options.selectable && aggregateColumns.length === 0">
      <p-checkbox [binary]="true" [(ngModel)]="selected" [disabled]="selectionDisabled" (ngModelChange)="onSelect($event)"></p-checkbox>
    </td>
    <td *ngFor="let column of columns" [ngStyle]="options.columns[column].style" [style.text-align]="options.columns[column].align || 'left'" [attr.title]="options.columns[column].ellipsisMode === 'ellipsed' ? options.columns[column].attr : ''">
      <ng-template cmpHost></ng-template>
    </td>
    <td *ngFor="let column of aggregateColumns" [ngStyle]="options.aggregation?.columns![column].style" [style.text-align]="options.aggregation?.columns![column].align || 'left'" [attr.title]="options.aggregation?.columns![column].ellipsisMode === 'ellipsed' ? options.aggregation?.columns![column].attr : ''">
      <ng-template cmpHost></ng-template>
    </td>
    <td *ngIf="routerLink" class="align-right">
      <a [routerLink]="routerLink">Details</a>
    </td>
  `,
})
export class TableRowComponent<T> {
  @Input() row!: any
  @Input() options!: TableOptions<T>
  @Input() columns: number[] = []
  @Input() aggregateColumns: number[] = []

  @Input() selected: boolean = false;
  @Input() selectionDisabled: boolean = false;
  @Output() selectedChange = new EventEmitter<boolean>();

  @ViewChildren(CmpHostDirective) hosts!: QueryList<CmpHostDirective>

  routerLink: string[] | null = null

  ngAfterViewInit() {
    this.hosts.changes.subscribe(() => {
      this.createComponents()
    })

    this.createComponents()

    this.routerLink = this.options.routerLink ? this.options.routerLink(this.row) : null
  }

  createComponents() {
    for (let i = 0; i < this.columns.length; i++) {
      const host = this.hosts.get(i)
      const originalIndex = this.columns[i]
      const column = this.options.columns[originalIndex]

      this.createComponent(host, column);
    }

    for (let i = 0; i < this.aggregateColumns.length; i++) {
      const host = this.hosts.get(i + this.columns.length)
      const originalIndex = this.aggregateColumns[i]
      const column = this.options.aggregation?.columns[originalIndex]!

      this.createComponent(host, column);
    }
  }

  createComponent(host: CmpHostDirective | undefined, column: TableColumn<any>) {
    if (column.ellipsisMode === 'ellipsed') {
      column.style = {
        ...column.style,
        maxWidth: column.ellipsisWidth,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }

    if (!host) {
      return;
    }
    
    const viewContainerRef = host.viewContainerRef
    viewContainerRef.clear()

    let componentRef = null;
    if ('component' in column) {
      const val = column.component(this.row)
      componentRef = viewContainerRef.createComponent(val.component);

      const componentAsAny = (componentRef.instance as any);
      for (const key of Object.keys(val.data as any)) {
        componentAsAny[key] = (val.data as any)[key]
      }
      if (val.output) {
        if (!componentAsAny['destroy$']) {
          throw `Component for ${column.name} must provide a "destroy$" subject when providing an output configuration.`;
        }
        for (const key of Object.keys(val.output as any)) {
          componentAsAny[key].pipe(takeUntil(componentAsAny['destroy$'])).subscribe((val.output as any)[key]);
        }
      }
    } else if ('actions' in column) {
      componentRef = viewContainerRef.createComponent(ActionsColumnComponent)

      componentRef.instance.row = this.row
      componentRef.instance.actions = column.actions

    } else if ('dropdown' in column) {
      componentRef = viewContainerRef.createComponent(DropdownColumnComponent);

      componentRef.instance.row = this.row;
      componentRef.instance.actions = column.dropdown;
    } else if (!column.format || column.format === 'string') {
      componentRef = viewContainerRef.createComponent(StringColumnComponent)

      componentRef.instance.row = this.row
      componentRef.instance.column = column.name
      
      if ('click' in column) componentRef.instance.click = column.click
      if ('routerLink' in column) componentRef.instance.routerLink = column.routerLink
      if ('icon' in column) componentRef.instance.icon = column.icon
      if (column.value) componentRef.instance.value = column.value

    } else if (column.format === 'subtask') {
      componentRef = viewContainerRef.createComponent(SubtaskColumnComponent);

      componentRef.instance.row = this.row;
      componentRef.instance.type = column.attr;
    } else if (column.format === 'user') {
      componentRef = viewContainerRef.createComponent(UserColumnComponent)

      componentRef.instance.row = this.row
      componentRef.instance.column = column.name

    } else if (column.format === 'fileSize') {
      componentRef = viewContainerRef.createComponent(FileSizeColumnComponent)

      componentRef.instance.row = this.row
      componentRef.instance.column = column.name

    } else if (column.format === 'tags') {
      componentRef = viewContainerRef.createComponent(TagsComponent)

      componentRef.instance.tags = this.row[column.name]

    } else if (column.format === 'date') {
      componentRef = viewContainerRef.createComponent(DateColumnComponent)

      componentRef.instance.row = this.row
      componentRef.instance.column = column.name

      if ('routerLink' in column) componentRef.instance.routerLink = column.routerLink
      if (column.dateFormat) componentRef.instance.format = column.dateFormat

    } else if (column.format === 'number') {
      componentRef = viewContainerRef.createComponent(NumberColumnComponent)

      componentRef.instance.row = this.row
      componentRef.instance.column = column.name

      if ('routerLink' in column) componentRef.instance.routerLink = column.routerLink
      if (column.numberFormat) componentRef.instance.numberFormat = column.numberFormat
      if (column.value) componentRef.instance.value = column.value
    }

    if (componentRef) {
      componentRef.changeDetectorRef.detectChanges();
    }
  }

  onSelect($event: boolean) {
    this.selected = $event;
    this.selectedChange.emit($event);
  }
}
