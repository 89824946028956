<div class="container">
  <div class="top">
    <div class="info">
      <div class="filename">{{ upload.information.fileName }}</div>
      <div class="description" *ngIf="upload.information.description">{{ upload.information.description }}</div>
    </div>
    <div class="status" *ngIf="state == 'error' || state == 'success'">
      <i *ngIf="state == 'success'" class="pi pi-check-circle"></i>
      <i *ngIf="state == 'error'" class="pi pi-times-circle"></i>
    </div>
  </div>
  <div class="progress" *ngIf="state == 'progress'">
    <div *ngIf="asNumber(upload.progressObservable | async) as progress">
      <p-progressBar *ngIf="progress >= 0" [value]="progress * 100" [showValue]="false"></p-progressBar>
      <div class="progress-info" *ngIf="progress >= 0">
        <div>{{ upload.information.size * progress | filesize }} of {{ upload.information.size |filesize }}</div>
        <div>{{ progress * 100 | number:'1.2-2' }}&nbsp;%</div>
      </div>
    </div>
  </div>
</div>