import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { User } from 'projects/api/src/api';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Environment } from '../../environments/environment';
import { UserService } from '../services/user.service';
import { authCodeFlowConfig } from './auth.config';
import { UtilsService } from '../services/utils.service';

export interface UserProfile {
  info: {
    sub: string
    nonce: string
    at_hash: string
    aud: string
    exp: number
    iat: number
    iss: string
    firstname: string
    lastname: string
    displayName: string
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authUserSubject = new BehaviorSubject<User | null>(null);
  public authUser = this.authUserSubject.asObservable();

  public get identityClaims() { return this.oauthService.getIdentityClaims(); }

  public get displayName(): string {
    return this.identityClaims ? (this.identityClaims as any)['displayName'] : 'User';
  }

  public get userId(): string {
    return this.identityClaims ? (this.identityClaims as any)['sub'] : '';
  }

  public get currentAuthUser(): User | null {
    return this.authUserSubject.value
  }

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private environment: Environment,
    private utilsService: UtilsService,
  ) {
    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup. See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');

      this.checkUser()
    });

    // this.oauthService.events.subscribe(_ => this.checkUser());
    // this.oauthService.events.pipe(filter(e => ['token_received'].includes(e.type))).subscribe(e => this.oauthService.loadUserProfile());
    // this.oauthService.events.pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type))).subscribe(e => this.navigateToLoginPage());
  }

  public async accessToken(): Promise<string | null> {
    try {
      if (!this.oauthService.hasValidAccessToken()) {
        await this.oauthService.silentRefresh()
        await this.checkUser()
      }

      return this.oauthService.getAccessToken() || null
    } catch (error) {
      return null;
    }
  }

  public async init(): Promise<User | null> {
    this.oauthService.configure(authCodeFlowConfig)
    this.oauthService.issuer = this.environment.authUrl
    
    await this.oauthService.loadDiscoveryDocument()

    if (new URL(document.URL).searchParams.has('login')) {
      await this.oauthService.initLoginFlow()
      return null
    } else {
      await this.oauthService.tryLogin()
      this.oauthService.setupAutomaticSilentRefresh();
    }
  
    return await this.checkUser()
  }

  public async navigateByState() {
    if (this.oauthService.state) {
      this.router.navigateByUrl(decodeURIComponent(this.oauthService.state))
      this.oauthService.state = undefined
    }
  }

  private async checkUser(retry = true): Promise<User | null> {
    if (this.oauthService.hasValidAccessToken()) {
      try {
        await this.oauthService.loadUserProfile()
      } catch (err) {
        await this.oauthService.silentRefresh()

        if (retry) {
          return this.checkUser(false)
        }
      }

      if (this.userId && this.userId !== this.currentAuthUser?._id) {
        const user: User = {
          _id: this.userId,
          displayName: this.displayName,
          type: 'User',
        }

        this.authUserSubject.next(user)

        return user
      } else {
        return this.currentAuthUser
      }
    } else {
      this.authUserSubject.next(null)
    }

    return null
  }

  public login(targetUrl?: string) {
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  public logout() { this.oauthService.revokeTokenAndLogout(); }
  public refresh() { this.oauthService.silentRefresh(); }
  public loginStatus() { return this.oauthService.hasValidAccessToken(); }
}
