<span *ngIf="field === 'assetType'">{{assetTask?.asset$?.assetType}}</span>
<span *ngIf="field === 'state'" class="state">
  <span class="color" [style.background-color]="stateColor"></span>
  <span class="text">{{task.state}}</span>
</span>
<span class="subtasks" *ngIf="field === 'openSubtasks'">
  <c-state-bar 
    [states]="openSubtaskStates"
  ></c-state-bar>
  <span class="own-open" *ngIf="hasOwnOpenSubtask" pTooltip="You have an open subtask">
    <i class="pi pi-exclamation-circle"></i>
  </span>
</span>
<span class="subtasks" *ngIf="field === 'closedSubtasks'">
  <c-state-bar 
    [states]="closedSubtaskStates"
    mode="percentage"
    [totalValue]="task.subtasksStatistic.count || 0"
  ></c-state-bar>
</span>