import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CreateAssetVersionSubtask, CreateAssetVersionSubtaskContext, CreateCameraSubtask, CreateCameraSubtaskContext, CreateVariantSetSubtaskContext, CreateVariantValueSubtaskContext, ManualSubtask, ManualSubtaskContext, OrderSubtask, OrderSubtaskSubtaskContext, Subtask, SubtaskContext } from 'projects/api/src/api';

@Pipe({
  name: 'subtask'
})
export class SubtaskNamePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(value: Subtask | OrderSubtask, type: string): SafeHtml {
    let name = '';
    if ((value as ManualSubtask).name !== undefined) {
      name = (value as ManualSubtask).name;
    }
    if (type === 'Subtask') {
      const subtask = value as Subtask;
      return this.sanitizer.bypassSecurityTrustHtml(`<span>${this.transformToSubtaskName(name, subtask.type, subtask.context)}</span>`);
    } else if (type === 'OrderSubtask') {
      const orderSubtask = value as OrderSubtask;
      return this.sanitizer.bypassSecurityTrustHtml(`<span>${this.transformToSubtaskName(name, orderSubtask.subtaskType, orderSubtask.subtaskContext)}</span>`);
    }

    return '';
  }

  private transformToSubtaskName(
    subtaskName: string,
    subtaskType: 'CreateAssetVersion' | 'CreateVariantSet' | 'CreateVariantValue' | 'CreateCamera' | 'Manual',
    subtaskContext: SubtaskContext | OrderSubtaskSubtaskContext
  ): string {
    let formattedName = '';
    switch (subtaskType) {
      case 'CreateAssetVersion': {
        const context = subtaskContext as CreateAssetVersionSubtaskContext;
        formattedName = `<span>${context.assetVersionName == 'latest' ? 'Create first version' : 'Create new version'}</span>`;
        break;
      }
      case 'CreateVariantSet': {
        const context = subtaskContext as CreateVariantSetSubtaskContext;
        formattedName = `<span>Create variant set <b>${context.variantSet}</b></span>`;
        break;
      }
      case 'CreateVariantValue': {
        const context = subtaskContext as CreateVariantValueSubtaskContext;
        formattedName = `<span>For variant set <b>${context.variantSet}</b>, create variant value <b>${context.variantValue}</b></span>`;
        break;
      }
      case 'CreateCamera': {
        const context = subtaskContext as CreateCameraSubtaskContext;
        formattedName = `<span>Create camera <b>${context.camera}</b></span>`;
        break;
      }
      case 'Manual': {
        formattedName = `<span>${subtaskName}</span>`;
        break;
      }
    }
    return `<span>${formattedName}</span>`;
  }
}
