import { Component, Inject, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-error-snackbar',
  templateUrl: './error-snackbar.component.html',
  styleUrls: ['./error-snackbar.component.scss']
})
export class ErrorSnackbarComponent {
  @Input() visible: boolean = false
  @Input() errorMessage: string = ''

  constructor(private messageService: MessageService) {
  }

  showError() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: this.errorMessage });
  }
}
