import { Inject, Injectable } from '@angular/core';
// PostAssetAssetVersionPreviewSetPreviewsBody, PostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview, PostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview,
import { Asset, AssetApiService, AssetVersion, BASE_PATH, GeneratedPreview, GetAssetAssetVersionBlockedVariantSelectionsQuery, GetAssetAssetVersionFilesQuery, GetAssetAssetVersionHistoryQuery, GetAssetAssetVersionPreviewSetsQuery, GetAssetAssetVersionQuery, GetAssetAssetVersionsQuery, GetAssetAssetVersionVariantSetsQuery, GetAssetHistoryQuery, GetAssetQuery, GetAssetsPreviewTemplatesQuery, GetAssetsQuery, PostAssetAssetVersionBlockedVariantSelectionsBody, PostAssetAssetVersionBlockedVariantSelectionsDeleteBody, PostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview, PostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview, PostAssetBody, PostAssetCommentsBody, Preview, PreviewSet } from 'projects/api/src/api';
import { Observable } from 'rxjs';
import { UploadService } from './upload.service';
import { UploadPreview } from 'projects/api/src/api';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class AssetService {

  activePreviewUploads: { [id: string]: Observable<number | 'Finished' | 'Error'> } = {}

  constructor(
    @Inject(BASE_PATH) private basePath: string,
    private assetApiService: AssetApiService,
    private uploadService: UploadService,
    private websocketService: WebsocketService,
  ) { }

  public query(query?: GetAssetsQuery) {
    return this.assetApiService.getAssets(query)
  }

  public get(asset: string, query?: GetAssetQuery) {
    return this.assetApiService.getAsset(asset, query)
  }

  public getAssetHistory(asset: string, query?: GetAssetHistoryQuery) {
    return this.assetApiService.getAssetHistory(asset, query)
  }

  public getAssetAssetVersionHistory(asset: string, assetVersion: string, query?: GetAssetAssetVersionHistoryQuery) {
    return this.assetApiService.getAssetAssetVersionHistory(asset, assetVersion, query)
  }

  public getAssetVersion(asset: string, assetVersion: string, query?: GetAssetAssetVersionQuery) {
    return this.assetApiService.getAssetAssetVersion(asset, assetVersion, query)
  }

  public getAssetVersions(asset: string, query?: GetAssetAssetVersionsQuery) {
    return this.assetApiService.getAssetAssetVersions(asset, query)
  }

  public getAssetVersionVariantSets(asset: string, assetVersion: string, query?: GetAssetAssetVersionVariantSetsQuery) {
    return this.assetApiService.getAssetAssetVersionVariantSets(asset, assetVersion, query)
  }

  public getAssetVersionPreviewSets(asset: string, assetVersion: string, query?: GetAssetAssetVersionPreviewSetsQuery) {
    return this.assetApiService.getAssetAssetVersionPreviewSets(asset, assetVersion, query)
  }

  public getAssetVersionFiles(asset: string, assetVersion: string, query?: GetAssetAssetVersionFilesQuery) {
    return this.assetApiService.getAssetAssetVersionFiles(asset, assetVersion, query);
  }

  public getAssetVersionFile(asset: string, assetVersion: string, fileId: string) {
    return this.assetApiService.getAssetAssetVersionFile(asset, assetVersion, fileId);
  }

  public getAssetVersionFileContent(asset: string, assetVersion: string, fileId: string) {
    return this.assetApiService.getAssetAssetVersionFileDownload(asset, assetVersion, fileId);
  }

  public getAssetVersionFileHistory(asset: string, assetVersion: string, fileId: string) {
    return this.assetApiService.getAssetAssetVersionFileHistory(asset, assetVersion, fileId);
  }

  public getPreviewTemplates(query?: GetAssetsPreviewTemplatesQuery) {
    return this.assetApiService.getAssetsPreviewTemplates(query)
  }

  public getPreviewTemplate(previewTemplate: string) {
    return this.assetApiService.getAssetsPreviewTemplate(previewTemplate)
  }

  public publish(asset: string, assetVersion: string) {
    return this.assetApiService.postAssetAssetVersionPublish(asset, assetVersion)
  }

  public reject(asset: string, assetVersion: string) {
    return this.assetApiService.postAssetAssetVersionReject(asset, assetVersion)
  }

  public createPreviewSet(asset: string, assetVersion: string) {
    return this.assetApiService.postAssetAssetVersionPreviewSets(asset, assetVersion)
  }

  public async createUploadPreview(asset: Asset, assetVersion: AssetVersion, previewSet: PreviewSet, data: PostAssetAssetVersionPreviewSetPreviewsBodyUploadPreview, file: File): Promise<UploadPreview> {
    const result = await this.assetApiService.postAssetAssetVersionPreviewSetPreviews(asset._id, assetVersion._id, previewSet._id, data)

    const uploadPreview = result[0] as null | UploadPreview

    if (!uploadPreview?.uploadUrl) {
      throw new Error('Could not create upload url')
    }

    return new Promise<UploadPreview>((resolve: (result: UploadPreview) => void, reject: (err: any) => void) => {
      this.activePreviewUploads[uploadPreview._id] = this.uploadService.upload(uploadPreview.uploadUrl!, file, {
        type: 'Preview',
        size: file.size,
        routerLink: ['/assets', asset._id, 'assetversions', assetVersion._id],
        description: `For asset ${asset.name}@${assetVersion.version}`,
        asset: asset._id,
        assetVersion: assetVersion._id,
        previewSet: previewSet._id,
        preview: uploadPreview._id,
        fileName: file.name,
      })

      const subscription = this.activePreviewUploads[uploadPreview._id].subscribe(async (status) => {
        if (status === 'Finished') {
          const [finishedPreview] = await this.emitPreviewUploadFinished(asset._id, assetVersion._id, previewSet._id, uploadPreview._id)
          delete this.activePreviewUploads[uploadPreview._id]
          subscription.unsubscribe()

          if (finishedPreview) {
            resolve(finishedPreview)
          } else {
            reject(new Error('Upload error'))
          }
        }
        if (status === 'Error') {
          await this.emitPreviewUploadError(asset._id, assetVersion._id, previewSet._id, uploadPreview._id)
          delete this.activePreviewUploads[uploadPreview._id]
          subscription.unsubscribe()
          reject(new Error('Upload error'))
        }
      })
    })
  }

  public async createGeneratedPreview(asset: string, assetVersion: string, previewSet: string, data: PostAssetAssetVersionPreviewSetPreviewsBodyGeneratedPreview) {
    return this.assetApiService.postAssetAssetVersionPreviewSetPreviews(asset, assetVersion, previewSet, data)
  }

  private emitPreviewUploadError(asset: string, assetVersion: string, previewSet: string, preview: string) {
    return this.assetApiService.postAssetAssetVersionPreviewSetPreviewError(asset, assetVersion, previewSet, preview)
  }

  private emitPreviewUploadFinished(asset: string, assetVersion: string, previewSet: string, preview: string) {
    return this.assetApiService.postAssetAssetVersionPreviewSetPreviewFinished(asset, assetVersion, previewSet, preview)
  }

  public deletePreview(asset: string, assetVersion: string, previewSet: string, preview: string) {
    return this.assetApiService.postAssetAssetVersionPreviewSetPreviewDelete(asset, assetVersion, previewSet, preview)
  }

  public processPreviewSet(asset: string, assetVersion: string, previewSet: string) {
    return this.assetApiService.postAssetAssetVersionPreviewSetProcess(asset, assetVersion, previewSet)
  }

  public publishPreviewSet(asset: string, assetVersion: string, previewSet: string) {
    return this.assetApiService.postAssetAssetVersionPreviewSetPublish(asset, assetVersion, previewSet)
  }

  public submit(asset: string, assetVersion: string) {
    return this.assetApiService.postAssetAssetVersionSubmit(asset, assetVersion)
  }

  public deleteAsset(asset: string) {
    return this.assetApiService.postAssetDelete(asset)
  }

  public createAssetComment(asset: string, body: PostAssetCommentsBody) {
    return this.assetApiService.postAssetComments(asset, body)
  }

  public updateAsset(asset: string, body: PostAssetBody) {
    return this.assetApiService.postAsset(asset, body)
  }

  public registerPreviewSet(previewSet: string): Observable<PreviewSet> {
    return this.websocketService.filtered<PreviewSet>('previewset', previewSet, 'previewset:update', (j) => j._id === previewSet)
  }

  public getBlockedVariantSelections(asset: string, assetVersion: string, query: GetAssetAssetVersionBlockedVariantSelectionsQuery) {
    return this.assetApiService.getAssetAssetVersionBlockedVariantSelections(asset, assetVersion, query)
  }

  public createBlockedVariantSelections(asset: string, assetVersion: string, body: PostAssetAssetVersionBlockedVariantSelectionsBody) {
    return this.assetApiService.postAssetAssetVersionBlockedVariantSelections(asset, assetVersion, body)
  }

  public deleteBlockedVariantSelections(asset: string, assetVersion: string, body: PostAssetAssetVersionBlockedVariantSelectionsDeleteBody) {
    return this.assetApiService.postAssetAssetVersionBlockedVariantSelectionsDelete(asset, assetVersion, body)
  }

  public async downloadAssetVersion(asset: string, assetVersion: string) {
    const [result] = await this.assetApiService.getAssetAssetVersionDownload(asset, assetVersion);
    if (result) {
      return `${this.basePath}/assets/${asset}/assetversions/${assetVersion}/download/${result.downloadKey}`;
    }

    throw new Error("Could not create download");
  }

  public isGeneratedPreview(preview?: Preview): preview is GeneratedPreview {
    return preview?.type === 'GeneratedPreview'
  }

}
