import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Tag } from 'projects/api/src/api';

@Component({
  selector: 'c-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit, OnChanges {

  @Input() tags?: Tag[] = []
  undeletedTags: string[] = []

  constructor(
  ) { }

  ngOnInit(): void {
    this.filterDeletedTags()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tags']) {
      this.filterDeletedTags()
    }
  }

  filterDeletedTags() {
    this.undeletedTags = this.tags?.filter(tag => !tag.deletedAt).map(tag => tag.value) || []
  }
}
