import { Injectable } from '@angular/core';
import { UserApiService } from 'projects/api/src/api';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private userApiService: UserApiService,
    private authService: AuthService,
  ) { }

  public getUserAgencies(userId: string) {
    return this.userApiService.getUserAgencies(userId);
  }

  public getInvitations() {
    return this.userApiService.getUserInvitations(this.authService.userId)
  }

  public acceptInvitation(invitation: string) {
    return this.userApiService.postUserInvitationAccept(this.authService.userId, invitation)
  }

  public declineInvitation(invitation: string) {
    return this.userApiService.postUserInvitationDecline(this.authService.userId, invitation)
  }
}
