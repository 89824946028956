import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({
  name: 'pastelColor'
})
export class PastelColorPipe implements PipeTransform {

  constructor(
    private utilsService: UtilsService,
  ) { }

  transform(value: string): string {
    return this.utilsService.pastelColor(value, 'string')
  }
  
}
