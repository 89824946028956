import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'projects/api/src/api';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  authUser: Observable<User | null>;

  constructor(
    private authService: AuthService,
  ) {
    this.authUser = this.authService.authUser
  }

  ngOnInit(): void {

  }

}
