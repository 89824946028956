
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Task, Subtask, User, Contract } from 'projects/api/src/api';
import { AgencyService } from 'projects/web/src/app/services/agency.service';
import { TaskService } from 'projects/web/src/app/services/task.service';
import { UserService } from 'projects/web/src/app/services/user.service';

@Component({
  selector: 'c-subtask-assign',
  templateUrl: './subtask-assign.component.html',
  styleUrls: ['./subtask-assign.component.scss']
})
export class SubtaskAssignComponent implements OnInit {

  @Input() task!: Task

  @Input() subtasks: Subtask[] = []
  
  @Output() changed: EventEmitter<void> = new EventEmitter<void>()

  loading = true
  ownSubtask = false
  contractSubtask = false

  users: User[] = []
  contracts: Contract[] = []

  ownSubtaskForm = new FormGroup({
    mode: new FormControl('ownSubtask'),
    assignee: new FormControl(''),
    contract: new FormControl(''),
  })

  contractSubtaskForm = new FormGroup({
    assignee: new FormControl(''),
  })
  
  constructor(
    private taskService: TaskService,
    private agencyService: AgencyService,
  ) { }

  async ngOnInit() {
    this.ownSubtask = this.task.agency === this.agencyService.currentSelectedAgencyId
    this.contractSubtask = this.subtasks.every(subtask => subtask.contract$?.contractor === this.agencyService.currentSelectedAgencyId)

    if (this.ownSubtask || this.contractSubtask) {
      const [users] = await this.agencyService.getUsers({
        limit: 99999,
        orderBy: 'displayName'
      })

      this.users = users?.items || []

      const usedAssignees = [...new Set(this.subtasks.map(s => s.assignee).filter(s => !!s))]
      const usedContracts = [...new Set(this.subtasks.map(s => s.contract).filter(s => !!s))]

      if (this.ownSubtask) {
        const [contracts] = await this.agencyService.queryContracts({
          valid: true,
          orderBy: 'name',
          limit: 99999,
        })
  
        this.contracts = contracts?.items || []
        
        if (usedAssignees.length === 1 && this.subtasks.every(s => s.assignee === usedAssignees[0])) {
          this.ownSubtaskForm.get('assignee')?.setValue(usedAssignees[0] || '')
        }

        if (usedContracts.length === 1 && this.subtasks.every(s => s.contract === usedContracts[0])) {
          this.ownSubtaskForm.get('contract')?.setValue(usedContracts[0] || '')
          this.ownSubtaskForm.get('mode')?.setValue('contractSubtask')
        }
      }
  
      this.ownTaskFormChanged()
  
      this.loading = false
    }
  }

  async submitOwnTask() {
    this.ownSubtaskForm.disable()

    // We will do it serial for now
    for (const subtask of this.subtasks) {
      if (this.ownSubtaskForm.get('mode')?.value === 'ownSubtask') {
        await this.taskService.assignSubtask(this.task._id, subtask._id, this.ownSubtaskForm.get('assignee')?.value || null, null)
      } else {
        await this.taskService.assignSubtask(this.task._id, subtask._id, null, this.ownSubtaskForm.get('contract')?.value || null)
      }
    }

    this.changed.emit()
  }

  async submitContractTask() {
    this.contractSubtaskForm.disable()

    // We will do it serial for now
    for (const subtask of this.subtasks) {
      await this.taskService.assignSubtask(this.task._id, subtask._id, this.contractSubtaskForm.get('assignee')?.value || null, subtask.contract!)
    }
    
    this.changed.emit()
  }

  ownTaskFormChanged() {
    if (this.ownSubtaskForm.get('mode')?.value === 'ownSubtask') {
      this.ownSubtaskForm.get('assignee')?.enable()
      this.ownSubtaskForm.get('contract')?.disable()
    } else {
      this.ownSubtaskForm.get('assignee')?.disable()
      this.ownSubtaskForm.get('contract')?.enable()
    }
  }

}