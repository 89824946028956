import { Component, ElementRef, Input, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { OrderService } from '../../services/order.service';
import { TaskService } from '../../services/task.service';
import { Comment, User } from '../../../../../api/src/api'
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'c-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {
  @ViewChild("comment") comment! : ElementRef;

  @Input() task?: string = ''
  @Input() orderId?: string = ''
  @Input() orderLineId?: string = ''

  authUser: Observable<User | null>

  comments: Comment[] = []
  addCommentForm = new FormGroup({
    comment: new FormControl('')
  })

  constructor(
    private taskService: TaskService,
    private orderService: OrderService,
    private authService: AuthService,
    public sanitizer: DomSanitizer
  ) {
    this.authUser = this.authService.authUser
  }

  async ngOnInit(): Promise<void> {
    if (this.task) {
      const [commentsResults] = await this.taskService.getTaskComments(this.task, {
        lookups: 'user',
        orderBy: 'createdAt',
        orderDirection: -1,
      })

      if (commentsResults) {
        this.comments = commentsResults.items
      }
    } else if (this.orderLineId && this.orderId) {
      const [commentsResults] = await this.orderService.getOrderOrderLineComments(this.orderId, this.orderLineId, {
        lookups: 'user',
        orderBy: 'createdAt',
        orderDirection: -1,
      })

      if (commentsResults) {
        this.comments = commentsResults.items
      }
    }
  }

  async onSubmit() {
    if (this.addCommentForm.get('comment')?.value! != '') {
      if (this.task) {
        const [result] = await this.taskService.postTaskComments(this.task, {
          message: this.addCommentForm.get('comment')?.value!
        })

        if (result) {
          this.comments.unshift(result)
        }
      } else if (this.orderLineId && this.orderId) {
        const [result] = await this.orderService.createOrderLineComment(this.orderId, this.orderLineId, {
          message: this.addCommentForm.get('comment')?.value!,
        })

        if (result) {
          this.comments.unshift(result)
        }
      }
    }

    this.addCommentForm.get('comment')?.setValue('')
  }
}
