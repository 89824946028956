import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from "@angular/core"
import { CmpHostDirective } from "./table.component"
import { TableOptions } from "./table.interfaces"
import { takeUntil } from "rxjs"

@Component({
  selector: '[c-tile]',
  template: `
    <div class="tile" (click)="toggleSelect()">
      <div class="container">
        <ng-template cmpHost></ng-template>
      </div>
      <div class="selector">
        <p-checkbox [binary]="true" [ngModel]="selected" [disabled]="selectionDisabled"></p-checkbox>
      </div>
    </div>
  `,
  styles: [`
    :host {
      display: block;

      .tile {
        position: relative;

        .selector {
          display: none;
          position: absolute;
          top: 8px;
          left: 8px;
        }
      }

      &.selection-preview {
        .tile {
          outline: 4px solid var(--primary-light);
        }
      }

      &.selection-mode.selected .tile {
        outline: 4px solid var(--background-dark);

        .container {
          outline: 8px solid var(--primary);
        }
      }

      &.selectable {
        cursor: pointer;

        .tile {
          &:hover {
            .selector {
              display: block;
            }
          }
        }
      }
    }
  `]
})
export class TileComponent<T> {
  @Input() row!: any
  @Input() options!: TableOptions<T>
  
  @HostBinding('class.selection-mode')
  @Input() 
  selectionMode: boolean = false

  @HostBinding('class.selected')
  @Input() 
  selected: boolean = false;

  @Input()
  selectionDisabled: boolean = false;
  
  @HostBinding('class.selectable')
  get selectable(): boolean {
    return this.options?.selectable || false
  }

  
  @Output() selectedChange = new EventEmitter<boolean>();

  @ViewChild(CmpHostDirective, {static: true}) host!: CmpHostDirective

  
  ngAfterViewInit() {
    this.createComponent()
  }

  createComponent() {

    const viewContainerRef = this.host.viewContainerRef
    viewContainerRef.clear()
    const val = this.options.tileView!.component(this.row)
    const componentRef = viewContainerRef.createComponent(val.component)
    
    const componentAsAny = (componentRef.instance as any);
    for (const key of Object.keys(val.data as any)) {
      componentAsAny[key] = (val.data as any)[key]
    }
    if (val.output) {
      if (!componentAsAny['destroy$']) {
        throw 'Component for tile view must provide a "destroy$" subject when providing an output configuration.';
      }
      for (const key of Object.keys(val.output as any)) {
        componentAsAny[key].pipe(takeUntil(componentAsAny['destroy$'])).subscribe((val.output as any)[key]);
      }
    }
  }
  
  onSelect($event: boolean) {
  }

  toggleSelect() {
    this.selected = !this.selected;
    this.selectedChange.emit(this.selected);
  }
}
