import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Task, Subtask, AssetTask } from 'projects/api/src/api';
import { AuthService } from 'projects/web/src/app/auth/auth.service';
import { AgencyService } from 'projects/web/src/app/services/agency.service';
import { TaskService } from 'projects/web/src/app/services/task.service';

@Component({
  selector: 'c-subtask',
  templateUrl: './subtask.component.html',
  styleUrls: ['./subtask.component.scss']
})
export class SubtaskComponent implements OnInit {

  @Input() task!: Task
  @Input() subtask!: Subtask

  @Input() selected: boolean = false
  @Input() showSelection: boolean = false

  @HostBinding('class.open')
  @Input()
  open = false
  
  @Output() changed: EventEmitter<void> = new EventEmitter<void>()
  @Output() selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>()

  @HostBinding('class')
  get subtaskClass(): string {
    return this.subtask?.state?.toLowerCase() || ''
  }

  @HostBinding('class.selected')
  get isSelected(): boolean {
    return this.selected || this.showSelection
  }

  loading = false

  assetTask: AssetTask | null = null
  resolvedAt: Date | null = null

  menuItems: MenuItem[] = []
  menuIsOpen = false

  assignVisible = false
  downloadVisible = false

  ownSubtask = false
  agencySubtask = false
  contractSubtask = false

  get downloadCommand(): string {
    return `imagejet download ${this.assetTask?.asset$?.name} --agency ${this.task?.agency$?.name}`
  }

  constructor(
    private taskService: TaskService,
    private agencyService: AgencyService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.ownSubtask = this.subtask.assignee === this.authService.userId,
    this.agencySubtask = this.task.agency === this.agencyService.currentSelectedAgencyId
    this.contractSubtask = this.subtask.contract$?.contractor === this.agencyService.currentSelectedAgencyId

    this.calculateProperties()
    this.buildMenu()
  }

  calculateProperties() {
    if (this.taskService.isAssetTask(this.task)) {
      this.assetTask = this.task
    }

    if (this.subtask.state === 'Done') {
      this.resolvedAt = this.subtask.stateHistory.reduce((a, b) => {
        if (b.state === 'Done') {
          const bDate = new Date(b.date)
          return !a || bDate.getTime() > a.getTime() ? bDate : a
        }
        return a
      }, null as null | Date)
    }
  }

  buildMenu() {
    this.menuItems = []

    // if (!this.subtask.generated) {
    //   this.menuItems.push({
    //     label: 'Edit',
    //     icon: 'pi pi-pencil',
    //     command: () => this.deleteSubtask()
    //   })
    // }

    // if (this.subtaskClass !== 'unassigned') {
    //   this.menuItems.push({label: 'Assign', icon: 'pi pi-users', command: () => { this.assignVisible = true }})
    // }

    const stateMenuItems: MenuItem[] = []

    if (!this.subtask.generated && this.subtask.state !== 'InProgress' && this.subtask.state !== 'New') {
      stateMenuItems.push({ label: 'New', icon: 'pi pi-star', command: () => this.setState('New') })
      stateMenuItems.push({ label: 'In Progress', icon: 'pi pi-play', command: () => this.setState('InProgress') })
    }
    
    if (!this.subtask.generated && this.subtask.state !== 'Done' && this.subtask.state !== 'InProgress') {
      stateMenuItems.push({ label: 'Done', icon: 'pi pi-check', command: () => this.setState('Done') })
    }

    if (stateMenuItems.length > 0) {
      this.menuItems.push({separator: true})
      this.menuItems.push({label: 'Set State', disabled: true})
      this.menuItems.push(...stateMenuItems)
    }

    // if (!this.subtask.generated) {
    //   this.menuItems.push({separator: true})
    //   this.menuItems.push({
    //     label: 'Delete',
    //     icon: 'pi pi-trash',
    //     command: () => this.deleteSubtask()
    //   })
    // }
  }

  async deleteSubtask() {
    this.loading = true

    try {
      // await this.taskService.setSubtaskState(this.task._id, this.subtask._id, state)
      this.changed.emit()
    } catch (err) {
      console.error(err)
    }

    this.loading = false

  }

  async setState(state: 'New' | 'InProgress' | 'Done') {
    this.loading = true

    try {
      await this.taskService.setSubtaskState(this.task._id, this.subtask._id, state)
      this.changed.emit()
    } catch (err) {
      console.error(err)
    }

    this.loading = false
  }

}
