<div class="headers">
  <div class="header-icons">
    <a class="header-icon" (click)="increaseSize()" [class.disabled]="size === 'wide'"><i class="pi pi-angle-left"></i></a>
    <a class="header-icon" (click)="decreaseSize()" [class.disabled]="size === 'collapsed'"><i class="pi pi-angle-right"></i></a>
  </div>
  <div class="header" *ngFor="let panel of panels; let i = index" (click)="selectTab(i)" [class.selected]="!panel.hidden">
    {{ panel.header }}
  </div>
</div>
<div class="content">
  <ng-content></ng-content>
</div>

