import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core"

@Component({
  template: '{{row[column] | date:format}}'
})
export class DateColumnComponent implements OnInit, OnChanges {
  row: any = {}
  column: string = ''
  format: string = 'medium'
  routerLink?: (row: any) => string[]
  calculatedRouterLink: string[] | null = null

  ngOnInit() {
    this.calculatedRouterLink = this.routerLink ? this.routerLink(this.row) : null
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["routerLink"]) {
      this.calculatedRouterLink = this.routerLink ? this.routerLink(this.row) : null
    }
  }
}
