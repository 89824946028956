import { UserIconComponent } from './user-icon.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MenuModule } from 'primeng/menu';


@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    UserIconComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    MenuModule
  ],
  exports: [
    UserIconComponent
  ],
  bootstrap: [UserIconComponent],
})
export class UserIconModule { }
