import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ErrorSnackbarComponent } from './error-snackbar.component';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';


@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    ErrorSnackbarComponent
  ],
  imports: [
    CommonModule,
    ToastModule,
  ],
  exports: [
    ErrorSnackbarComponent
  ],
  providers: [
    MessageService
  ]
})
export class ErrorSnackbarModule { }
