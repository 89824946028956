import { Pipe, PipeTransform } from '@angular/core';
import { Job } from 'projects/api/src/api';
import { of, interval, map, Observable } from 'rxjs';

@Pipe({
  name: 'processingTime'
})
export class ProcessingTimePipe implements PipeTransform {

  transform(value: Job, ...args: unknown[]): Observable<string> | Observable<undefined> {
    return this.getProcessingTime(value);
  }

  private msToHMS(ms: number) {
    const seconds = Math.floor((ms / 1000) % 60)
    const minutes = Math.floor((ms / (1000 * 60)) % 60)
    const hours = Math.floor(ms / (1000 * 60 * 60))
    const hoursFormatted = (hours < 10) ? "0" + hours : hours
    const minutesFormatted = (minutes < 10) ? "0" + minutes : minutes
    const secondsFormatted = (seconds < 10) ? "0" + seconds : seconds
    return hoursFormatted + ":" + minutesFormatted + ":" + secondsFormatted
  }
  private getProcessingTime(job: Job): Observable<string> | Observable<undefined> {
    const runningStates =  ["Running", "Downloading"]
    const stateRunning = job.stateHistory.find((stateHistoryItem) => (runningStates.includes(stateHistoryItem.state)))
    const completedStates = ["Success", "Error", "Canceled"]
    const stateCompleted = job.stateHistory.find((stateHistoryItem) => (completedStates.includes(stateHistoryItem.state)))
    if (stateRunning) {
      if (stateCompleted) { 
        return of(this.msToHMS(new Date(stateCompleted.date).getTime() - new Date(stateRunning.date).getTime()))
      } else {
        return interval(1000).pipe(
          map(() => {
            return this.msToHMS(new Date().getTime() - new Date(stateRunning.date).getTime())
          })
        )
      }
    } else {
      return of(undefined)
    }
  }
}
