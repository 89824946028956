import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotEqualsCondition, Task } from 'projects/api/src/api';
import { Breadcrumb } from '../../components/header/header.component';
import { TableOptions, TableQuery } from '../../components/table/table.interfaces';
import { taskStates } from '../../services/state.service';
import { TaskService } from '../../services/task.service';
import { TaskColumnComponent } from './task-column/task-column.component';
import { AgencyService } from '../../services/agency.service';


@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  tasks: Task[] = []
  tableOptions: TableOptions<Task>

  breadcrumbs: Breadcrumb[] = []

  constructor(
    private taskService: TaskService,
    private agencyService: AgencyService,
    private router: Router,
  ) {
    this.tableOptions = {
      columns: [
        { name: 'name', header: 'Name', routerLink: (task) => ['/tasks', task._id] },
        { 
          name: 'tags', 
          header: 'Tags', 
          format: 'tags',          
          filter: {
            path: 'tags.value',
            conditionTypes: ['In', 'NotIn'],
            type: 'string',
            values: this.agencyService.getTags().then(agencyTags => {
              return (agencyTags.taskTags || []).map(tag => ({
                label: tag,
                value: tag,
              }))
            })
          }
        },
        { name: 'assetType', header: 'Asset Type', component: (task) => ({
          component: TaskColumnComponent,
          data: { task: task, field: 'assetType' }
        })},
        { name: 'createdAt', header: 'Created At', format: 'date' },
        { name: 'createdBy$', header: 'Created By', format: 'user' },
        { 
          name: 'state', 
          header: 'State',
          component: (task) => ({
            component: TaskColumnComponent,
            data: { task: task, field: 'state' }
          }),
          filter: {
            path: 'state',
            conditionTypes: ['In', 'NotIn'],
            type: 'string',
            values: [...taskStates].map(t => ({
              label: t,
              value: t,
            })),
          }
        },
        { 
          name: 'openSubtasks', 
          header: 'Open Subtasks',
          component: (task) => ({
            component: TaskColumnComponent,
            data: { task: task, field: 'openSubtasks' }
          }),
        },
        { 
          name: 'closedSubtasks', 
          header: 'Closed Subtasks',
          component: (task) => ({
            component: TaskColumnComponent,
            data: { task: task, field: 'closedSubtasks' }
          }),
        },
        { name: 'assignees$', header: 'Assignees', format: 'user' },
        { name: 'contracts$', header: 'Via Contracts', value: (task) => (task.contracts$ || []).map(c => c.name).join(', ') },
      ],
      search: true,
      defaultSort: 'createdAt',
      defaultSortDirection: 'desc',
      predefinedFilters: [
        {
          _id: 'open',
          name: 'Open',
          filter: {
            type: 'NotEquals',
            path: 'state',
            value: 'Done'
          } as NotEqualsCondition
        }
      ]
    }

    this.breadcrumbs = [
      { title: 'Tasks' }
    ]
   }

  async ngOnInit(): Promise<void> {
  }

  navigateToUrl(taskId: string) {
    this.router.navigate(['/tasks/' + taskId ]);
  }

  query(query: TableQuery<Task>) {
    query.result = this.taskService.getTasks({
      ...query.query,
      lookups: 'createdBy,assignees,contracts,asset'
    })
  }
}
