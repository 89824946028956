import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UtilsService } from '../../services/utils.service';

export interface State {
  name: string;
  value: number;
  color: string;
}

@Component({
  selector: 'c-state-bar',
  templateUrl: './state-bar.component.html',
  styleUrls: ['./state-bar.component.scss']
})
export class StateBarComponent implements OnInit, OnChanges {

  @Input() states: State[] = []
  @Input() totalValue: number | null = null

  tooltip: string = ''
  stateValue: number = 0

  bars: {
    width: string,
    color: string,
  }[] = []

  constructor(
    private utilsService: UtilsService,
  ) { }

  ngOnInit(): void {
    this.calculate()
  }

  ngOnChanges() {
    this.calculate()
  }

  calculate() {
    this.bars = []

    const totalValue = this.totalValue || this.states.reduce((a, b) => a + b.value, 0)

    this.stateValue = 0
    let tooltip = ''
    
    for (const state of this.states) {
      const stateValue = state.value || 0
      const color = state.color || 'black'

      this.bars.push({
        color: color,
        width: `${totalValue > 0 ? ((this.stateValue + stateValue) * 1.0 / totalValue) * 100 : 0}%`
      })

      this.stateValue += stateValue
      tooltip += `
        <div class="state">
          <div class="color" style="background-color: ${color};"></div>
          <div class="text"><div class="value">${stateValue.toLocaleString()}</div>${this.utilsService.capitalizeFirstLetter(state.name)}</div>
        </div>
      `.replace(/\n/g, '')
    }

    this.tooltip = `<div>${tooltip}</div>`
  }
}
