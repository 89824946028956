import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StarRatingModule } from '../../star-rating/star-rating.module';
import { AssetTilesviewComponent } from './asset-tilesview.component';
import { SearchBoxModule } from '../../search-box/search-box.module';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    AssetTilesviewComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    StarRatingModule,
    PipesModule,
    SearchBoxModule,
  ],
  exports: [
    AssetTilesviewComponent
  ]
})
export class AssetTilesviewModule { }
