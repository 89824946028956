import { Pipe, PipeTransform } from '@angular/core';
import { AssetPreviewSet } from 'projects/api/src/api';
import { Environment } from '../../environments/environment';

@Pipe({
  name: 'assetPreviewSetImage'
})
export class AssetPreviewSetImagePipe implements PipeTransform {

  constructor(
    private environment: Environment
  ) { }

  transform(previewSet?: AssetPreviewSet, index: number = 0, type?: 'large' | 'thumbnail'): string {
    if (!previewSet) return ''

    if (index === -1) {
      if (type === 'thumbnail') return this.url(previewSet.thumbnailUrl);
      return this.url(previewSet.url);
    }

    if (!previewSet.previews) return ''
    if (!previewSet.previews[index]) return ''

    if (type === 'thumbnail') return this.url(previewSet.previews[index].thumbnailUrl);
    
    return this.url(previewSet.previews[index].url);
  }

  url(path: string): string {
    return `${this.environment.cdnBasePath}${!path.startsWith('/') ? '/' : ''}${path}`
  }

}
