import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { ErrorSnackbarModule } from '../../components/error-snackbar/error-snackbar.module';
import { HeaderModule } from '../../components/header/header.module';
import { StateBarModule } from '../../components/state-bar/state-bar.module';
import { TableModule } from '../../components/table/table.module';
import { PipesModule } from '../../pipes/pipes.module';
import { TaskColumnComponent } from './task-column/task-column.component';
import { TaskDetailComponent } from './task-detail/task-detail.component';
import { TasksComponent } from './tasks.component';
import { TooltipModule } from 'primeng/tooltip';
import { SubtasksComponent } from './subtasks/subtasks.component';
import { SubtasksModule } from './subtasks/subtasks.module';

const routes: Routes = [
  { path: '', component: TasksComponent},
  { path: 'subtasks', component: SubtasksComponent },
  { path: ':taskId', component: TaskDetailComponent },
];

@NgModule({
  declarations: [
    TasksComponent,
    TaskColumnComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ErrorSnackbarModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    TableModule,
    HeaderModule,
    PipesModule,
    StateBarModule,
    TooltipModule,
    SubtasksModule
  ]
})
export class TasksModule { }
