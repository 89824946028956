import { Pipe, PipeTransform } from '@angular/core';
import { AssetVersion } from 'projects/api/src/api';
import { AssetService } from '../services/asset.service';

@Pipe({
  name: 'assetVersion'
})
export class AssetVersionPipe implements PipeTransform {

  constructor(
    private assetService: AssetService,
  ) { }

  transform(assetVersionId?: string, lookups?: string): Promise<AssetVersion | null> {
    if (!assetVersionId) return Promise.resolve(null)

    return this.assetService.getAssetVersion('any', assetVersionId, {
      lookups: lookups,
    }).then(([assetVersion, status, err]) => {
      return assetVersion
    })
  }

}
