import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core"

@Component({
  template: '<a *ngIf="calculatedRouterLink" [routerLink]="calculatedRouterLink">{{calculatedValue | number:numberFormat}}</a><span *ngIf="!calculatedRouterLink">{{calculatedValue | number:numberFormat}}</span>'
})
export class NumberColumnComponent implements OnInit, OnChanges {
  row: any = {}
  column: string = ''
  routerLink?: (row: any) => string[]
  calculatedRouterLink: string[] | null = null
  numberFormat: string = ''
  
  value?: (row: any) => number
  calculatedValue: number = 0

  ngOnInit() {
    this.calculatedRouterLink = this.routerLink ? this.routerLink(this.row) : null
    this.calculateValue()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["routerLink"]) {
      this.calculatedRouterLink = this.routerLink ? this.routerLink(this.row) : null
    }

    this.calculateValue()
  }

  calculateValue() {
    if (this.value) {
      this.calculatedValue = this.value(this.row)
    } else {
      this.calculatedValue = this.row[this.column]
    }
  }
}
