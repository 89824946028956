import { NgIf } from '@angular/common';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { RbacService } from '../../services/rbac.service';

@Directive({
  selector: '[hasRight]',
})
export class HasRightDirective {
  private _rights: string[] | null = null;
  private _ngIfDirective: NgIf;
  private _elseTemplateRef: TemplateRef<any> | null = null

  @Input()
  public set hasRight(val: string | string[] | { resource: string; rights: string | string[] }) {
    if (val) {
      if (typeof val === 'string') {
        this._rights = [val];
      } else if (Array.isArray(val)) {
        this._rights = val;
      } else {
        this._rights = typeof val.rights === 'string' ? [val.rights] : val.rights;
      }
      this.setNgIf();
    }
  }

  @Input()
  set hasRightElse(templateRef: TemplateRef<any>|null) {
    this._elseTemplateRef = templateRef
  }

  constructor(
    private templateRef: TemplateRef<any>, 
    private viewContainer: ViewContainerRef, 
    private rbacService: RbacService,
  ) {
    this._ngIfDirective = new NgIf(this.viewContainer, this.templateRef);
    this.setNgIf();
  }

  async setNgIf() {
    if (this._rights) {
      if (this._rights.length > 0) {
        this._ngIfDirective.ngIf = await this.rbacService.hasRight(this._rights);
      } else {
        this._ngIfDirective.ngIf = true;
      }
      this._ngIfDirective.ngIfElse = this._elseTemplateRef
    }
  }
}
