import { ChangeDetectorRef, Component, Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import fuzzy from "fuzzy";
import * as md5 from 'md5';
import { AndCondition, Condition, InCondition } from 'projects/api/src/api';
import { Subscription } from 'rxjs';
import { ConditionType, TableAggregationQuery, TableOptions, TableQuery, TableQueryResult, TableRefreshOptions, defaultConditionTypes } from './table.interfaces';
import { ConditionService } from '../../services/condition.service';

type ColumnSetting = {
  visible: boolean,
  originalIndex: number,
  hash: string,
}

type FilterSetting = {
  header: string,
  path: string,
  condition: Condition,
  conditionTypes: ConditionType[],
}

type GroupByColumnSetting = {
  include: boolean,
  originalIndex: number,
  hash: string
}

@Directive({
  selector: '[cmpHost]',
})
export class CmpHostDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
}

export class DragDropListHandler {
  private oldIndex: number = -1;
  private oldDisplayState: string = '';

  private draggedItem: any;
  private dropIndicator: HTMLDivElement | null = null;

  private dragOverHandler = ($event: DragEvent) => this.dragOver($event, -1);
  private dropHandler = () => this.drop();

  constructor(private list: any[], private listElements: QueryList<ElementRef>) {
    this.dropIndicator = document.createElement('div') as HTMLDivElement;
  }

  public drop() {
    if (this.dropIndicator) {
      this.list?.splice(this.oldIndex, 1);
      let insertIndex = Array.prototype.indexOf.call(this.dropIndicator?.parentElement?.children, this.dropIndicator);
      if (this.oldIndex < insertIndex) {
        --insertIndex;
      }
      this.list?.splice(insertIndex, 0, this.draggedItem!);
      this.oldIndex = insertIndex;
    }
    this.draggedItem = null;
  }

  public dragStart($event: DragEvent, index: number) {
    $event.dataTransfer!.effectAllowed = 'all';
    $event.dataTransfer!.dropEffect = 'move';

    this.oldIndex = index;
    this.draggedItem = this.list![index];

    setTimeout(() => {
      const draggedElement = this.listElements.toArray()[index].nativeElement as HTMLElement;
      this.dropIndicator!.style.height = `${draggedElement.getBoundingClientRect().height}px`;
      this.dropIndicator!.style.outline = 'dashed var(--gray-light) 1px';
      this.dropIndicator!.style.outlineOffset = '-1px';
      this.dropIndicator?.addEventListener('dragover', this.dragOverHandler);
      this.dropIndicator?.addEventListener('drop', this.dropHandler);
      this.listElements.toArray()[index].nativeElement.before(this.dropIndicator);
      this.oldDisplayState = draggedElement.style.display;
      draggedElement.style.display = 'none';
    }, 10);
  }

  public dragEnd() {
    if (this.dropIndicator) {
      this.dropIndicator?.removeEventListener('dragover', this.dragOverHandler);
      this.dropIndicator?.removeEventListener('drop', this.dropHandler);
      this.dropIndicator?.remove();
    }

    let draggedElement = this.listElements.toArray()[this.listElements.length - 1].nativeElement;
    if (this.oldIndex < this.listElements.length) {
      draggedElement = this.listElements.toArray()[this.oldIndex].nativeElement;
    }
    draggedElement.style.display = this.oldDisplayState;
    this.draggedItem = null;
    this.oldIndex = -1;
    this.oldDisplayState = '';
  }

  public dragOver($event: DragEvent, index: number) {
    $event.preventDefault();

    if (index >= 0) {
      let element = this.listElements.toArray()[index].nativeElement as HTMLElement;

      const bbox = element?.getBoundingClientRect();
      const halfHeight = 0.5 * bbox!.height;

      if ($event.offsetY <= halfHeight) {
        if (element?.parentElement!.contains(this.dropIndicator)) {
          this.dropIndicator?.remove();
        }
        element?.after(this.dropIndicator!);
      } else {
        if (element?.parentElement!.contains(this.dropIndicator)) {
          this.dropIndicator?.remove();
        }
        element?.before(this.dropIndicator!);
      }
    }
  }
}

@Component({
  selector: 'c-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent<T, A = any> implements OnInit, OnChanges, OnDestroy {

  @Input()
  options: TableOptions<T> = {
    columns: [],
  };

  @Input()
  rows: T[] | null = []

  @Input()
  aggregatedRows: A[] | null = [];

  @Input()
  header: string = ""

  @Input()
  id: string | null = null

  @Output()
  query: EventEmitter<TableQuery<T>> = new EventEmitter<TableQuery<T>>()

  @Output()
  groupByQuery: EventEmitter<TableAggregationQuery<A>> = new EventEmitter<TableAggregationQuery<A>>();

  @Input()
  selected: T[] = []

  @Input()
  selectionDisabled: boolean = false

  @Output()
  selectedChange: EventEmitter<T[]> = new EventEmitter<T[]>()

  @ViewChildren("groupByDragElements")
  groupByDragElements: QueryList<ElementRef> | null = null;

  @ViewChildren("columnDragElements")
  columnDragElements: QueryList<ElementRef> | null = null;

  @ViewChild("searchColumnsBox")
  searchColumnsBox: ElementRef | null = null;

  @ViewChild("searchGroupByBox")
  searchGroupByBox: ElementRef | null = null;

  fuzzyIcon = "pi pi-minus"

  lastSelected: T | null = null
  lastHovered: T | null = null
  shiftPressed = false
  selectionPreview: T[] = []
  selectAllValue: boolean | null = null;

  @HostBinding('class.hide')
  get hide(): boolean {
    return this.options.hideIfEmpty && this.rows?.length === 0 || false
  }

  @HostListener('document:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    this.shiftPressed = event.shiftKey;

    if (this.shiftPressed && this.lastHovered) {
      const index = this.rows?.indexOf(this.lastHovered) || -1
      if (index >= 0) {
        this.tileHover(index)
      }
    }
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyup(event: KeyboardEvent) {
    this.selectionPreview = []
    this.shiftPressed = event.shiftKey;
  }

  searchTerm: string = ''
  totalCount: number | null = null
  defaultSize: number = 100
  first: number = 0
  loading = true

  /**
   * An array of the original indexes. Defines the order and if a column is visible
   */
  columns: number[] = []
  aggregateColumns: number[] = []

  showColumnSettingsEdit = false
  columnSettings: ColumnSetting[] | null = null;
  columnSettingsEdit: ColumnSetting[] | null = null;
  availableColumns: ColumnSetting[] | null = null;
  chosenColumns: ColumnSetting[] | null = null;
  columnDragStartIndex = 0
  filteredColumns: ColumnSetting[] | null = null;

  currentColumns: ColumnSetting[] = [];

  showFilterSettingsEdit = false
  filterSettings: FilterSetting[] | null = null;
  filterSettingsEdit: FilterSetting[] | null = null;
  filteredFilters: FilterSetting[] | null = null;
  filterSuggestions: { [path: string]: { label: string, value: string }[] | null } = {}

  currentFilters: FilterSetting[] = []
  currentPredefinedFilter?: Condition

  showGroupByColumnSettingsEdit = false;
  groupByColumnSettings: GroupByColumnSetting[] | null = null;
  groupByColumnSettingsEdit: GroupByColumnSetting[] | null = null;
  availableGroupByColumns: GroupByColumnSetting[] | null = null;
  chosenGroupByColumns: GroupByColumnSetting[] | null = null;
  filteredGroupColumns: GroupByColumnSetting[] | null = null;

  dragDropListHandler: DragDropListHandler | null = null;

  currentGroupByColumns: GroupByColumnSetting[] = [];

  subscriptions: Subscription[] = []

  lastQueryParamsHash: string = ''

  tileView = false

  public get isTileView() {
    return this.tileView
  }

  get size(): number {
    return this.options.size || this.defaultSize
  }

  get pageIndex(): number {
    return this.first / this.size
  }

  get queryParamPrefix(): string {
    return this.options.queryParamPrefix || 't'
  }

  get localStorageFiltersKey(): string {
    return `table_${this.id}_filters`
  }

  get localStorageColumnsKey(): string {
    return `table_${this.id}_columns`
  }

  get localStorageGroupColumnsKey(): string {
    return `table_${this.id}_groupby`;
  }

  get localStorageTileViewKey(): string {
    return `table_${this.id}_tileview`
  }

  constructor(
    private cdref: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private conditionService: ConditionService,
  ) { }

  ngOnInit(): void {
    this.selected = [];
    let savedFilterChecked = false
    let savedGroupByChecked = false
    this.subscriptions.push(this.activatedRoute.queryParams.subscribe(async (queryParams) => {
      const filters: FilterSetting[] = [];
      if (!savedFilterChecked) {
        savedFilterChecked = true

        if (this.id) {
          const relevantParams = this.relevantQueryParams(queryParams)

          // only check the filter settings, if there isn't any relevant queryParam
          if (Object.keys(relevantParams).length === 0) {
            const savedFilterSettings = JSON.parse(localStorage.getItem(this.localStorageFiltersKey) || '[]') as FilterSetting[]

            if (Object.keys(savedFilterSettings).length > 0) {
              for (const savedFilterSetting of savedFilterSettings) {
                const filterSetting = this.filterSettings?.find(f => f.path === savedFilterSetting.path)

                if (filterSetting) {
                  if (filterSetting.conditionTypes.includes(savedFilterSetting.condition.type)) {
                    const condition = savedFilterSetting.condition

                    filterSetting.condition = condition
                    filters.push(filterSetting)
                  }
                }
              }
            }
          }
        }
      }

      let savedGroupBySettings: GroupByColumnSetting[] = [];
      
      if ( !savedGroupByChecked) {
        savedGroupByChecked = true;
        if (this.id) {
          const relevantParams = this.relevantQueryParams(queryParams);
          if (Object.keys(relevantParams).length === 0) {
            savedGroupBySettings = JSON.parse(localStorage.getItem(this.localStorageGroupColumnsKey) || '[]') as GroupByColumnSetting[];
          }
        }
      }

      if (filters.length > 0) {
        this.setCurrentFilters(filters);
      }

      if (Object.keys(savedGroupBySettings).length > 0) {
        this.setGroupBySettings(savedGroupBySettings);
      }

      if (filters.length > 0 || Object.keys(savedGroupBySettings).length > 0) {
        this.setQueryParams();
        return;
      }

      const hash = this.queryParamsHash(queryParams)

      if (hash != this.lastQueryParamsHash) {
        this.parseQueryParams(queryParams)
      }

      if (this.options.aggregation?.columns) {
        this.aggregateColumns = [...Array(this.options.aggregation.columns.length).keys()];
      }
    }))
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["options"]) {

      this.tileView = localStorage.getItem(this.localStorageTileViewKey) === 'true'

      const savedColumnsJson = localStorage.getItem(this.localStorageColumnsKey);
      const savedColumnSettings = JSON.parse(savedColumnsJson || '[]') as ColumnSetting[]
      this.currentColumns = savedColumnSettings.filter(c => c.visible);
      
      this.columnSettings = this.options.columns.map((c, i) => {
        const hash = md5(JSON.stringify(c));
        return {
          hash: md5(JSON.stringify(c)),
          visible: this.currentColumns.find(column => column.hash === hash) != undefined,
          originalIndex: i,
        }
      });

      if (savedColumnsJson === null) {
        this.columnSettings.forEach(c => { if (
          this.options.columns[c.originalIndex].visible === 'fixed' ||
          typeof this.options.columns[c.originalIndex].visible === 'undefined' ||
          this.options.columns[c.originalIndex].visible === true) {
            c.visible = true;
          }
        });
        this.currentColumns = this.columnSettings.filter(c => c.visible);
      }

      this.filterSettings = this.options.columns.filter(c => c.filter).map(c => {
        const conditionTypes = c.filter?.conditionTypes || defaultConditionTypes['string']
        const conditionType = conditionTypes[0]

        const condition = (() => {
          if (this.conditionService.isConditionWithStringValues({ type: conditionType })) {
            return {
              type: conditionType,
              path: c.filter!.path,
              values: [],
            }
          } else {
            return {
              type: conditionType,
              path: c.filter!.path,
              value: '',
            }
          }
        })()

        return {
          header: c.header || c.filter!.path,
          path: c.filter!.path,
          condition: condition,
          conditionTypes: conditionTypes,
        }
      })

      // tmp
      if (this.options.predefinedFilters && this.options.predefinedFilters.length > 0) {
        // this.currentPredefinedFilter = this.options.predefinedFilters[0].filter
      }

      this.currentGroupByColumns = JSON.parse(localStorage.getItem(this.localStorageGroupColumnsKey) || '[]') as GroupByColumnSetting[];
      this.groupByColumnSettings = this.options.columns.map((c, i) => {
        const hash = md5(JSON.stringify(c));
        return {
          include: this.currentGroupByColumns.find(groupByColumn => groupByColumn.hash === hash) != undefined,
          originalIndex: this.options.aggregation?.sourceColumns.includes(c.filter?.path!) ? i : -1,
          hash: hash,
        }
      }).filter(groupByColumn => groupByColumn.originalIndex >= 0);

      if (this.currentGroupByColumns.length > 0) {
        this.columns = this.currentGroupByColumns.map(groupByColumn => groupByColumn.originalIndex);
      } else {
        this.columns = this.currentColumns?.filter(c => c.visible).map((c) => c.originalIndex) || []
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  validCondition(condition: Condition): boolean {
    if (this.conditionService.isConditionWithStringValue(condition) && condition.value) return true
    if (this.conditionService.isConditionWithStringValues(condition) && condition.values && condition.values.length > 0) return true
    if (this.conditionService.isConditionWithDateValue(condition) && condition.value) return true
    return false
  }

  /**
   * Just temp. Should be an "advanced condition system" instead, so that just an AndCondition needs to be added
   * @param additionalConditions 
   */
  addInConditions(additionalConditions: InCondition[]) {
    let changes = false

    if (this.filterSettings) {
      for (const additionalCondition of additionalConditions) {
        if (this.conditionService.isConditionWithPath(additionalCondition)) {
          const filterSetting = this.filterSettings.find(f => f.path === additionalCondition.path)

          if (filterSetting && filterSetting.conditionTypes.includes('In')) {
            const values: string[] = []

            if (this.conditionService.isConditionWithStringValue(filterSetting.condition) && (!filterSetting.condition.value || filterSetting.condition.type === 'Equals')) {
              values.push(...[
                ...(filterSetting.condition.value ? [filterSetting.condition.value] : []),
                ...additionalCondition.values
              ])
            } else if (this.conditionService.isConditionWithStringValues(filterSetting.condition) && (!filterSetting.condition.values.every(v => !v) || filterSetting.condition.type === 'In')) {
              values.push(...[
                ...filterSetting.condition.values,
                ...additionalCondition.values,
              ])
            }

            if (values.length > 0) {
              filterSetting.condition = {
                type: 'In',
                path: additionalCondition.path,
                values: [...new Set(values)]
              } as InCondition

              changes = true
            }
          }
        }
      }

      if (changes) {
        this.setCurrentFilters(this.filterSettings.filter(s => this.validCondition(s.condition)))    
        this.setQueryParams(0)
      }
    }
  }

  async refresh(refreshOptions?: TableRefreshOptions) {
    this.loading = refreshOptions?.showLoading === false ? false : true

    this.selected = [];
    this.selectAllValue = null;
    this.selectedChange.emit(this.selected)

    const currentFilter = this.currentFilter();

    if (this.currentGroupByColumns.length > 0) {
      const tableGroupByQuery: TableAggregationQuery<A> = {
        query: {
          skip: this.first,
          limit: this.size,
          searchTerm: this.searchTerm,
          ...(currentFilter ? {
            filter: currentFilter
          } : {}),
          groupBy: this.currentGroupByColumns.map(groupByColumn => this.options.columns[groupByColumn.originalIndex].filter?.path!)
        }
      }
      this.groupByQuery.emit(tableGroupByQuery);
      if (typeof tableGroupByQuery.result === 'object') {
        await this.loadTable(tableGroupByQuery.result, true);
      }
    } else {
      const tableQuery: TableQuery<T> = {
        query: {
          skip: this.first,
          limit: this.size,
          searchTerm: this.searchTerm,
          ...(currentFilter ? {
            filter: currentFilter
          } : {}),
          ...(this.options.defaultSort ? { orderBy: this.options.defaultSort } : {}),
          ...(this.options.defaultSortDirection ? { orderDirection: (this.options.defaultSortDirection === 'asc' ? 1 : -1) } : {}),
        }
      }
      this.query.emit(tableQuery);

      if (typeof tableQuery.result === 'object') {
        await this.loadTable(tableQuery.result);
      }
    }

    this.loading = false
    this.cdref.detectChanges();
  }

  async loadTable(queryResult: TableQueryResult<T> | TableQueryResult<A>, isAggregateResult = false) {
    const result = await Promise.resolve(queryResult)

    if (Array.isArray(result)) {
      const [items, status, error] = result

      if (items?.items) {

        if (items.items.length === 0 && items.totalCount > 0) {
          // we are not on a valid page anymore (this.first is too large)
          // we will reload and get the last page
          this.first = (Math.floor(items.totalCount * 1.0 / this.size) - (items.totalCount % this.size === 0 ? 1 : 0)) * this.size
          this.refresh()
          return
        } else {
          if (isAggregateResult) {
            this.aggregatedRows = (items.items || []) as A[];
          } else {
            this.rows = (items.items || []) as T[]
          }
          this.totalCount = items.totalCount || null
        }

      } else if (error) {
        // show error
      }
    } else {
      if (isAggregateResult) {
        this.aggregatedRows = (result.items || []) as A[];
      } else {
        this.rows = (result.items || []) as T[]
      }
      this.totalCount = result.totalCount || null
    }
  }

  /**
   * Extracts the query params that are relevant for this table. Furthermore, it removes the prefix.
   * @param params
   * @returns
   */
  relevantQueryParams(params: Params): { [key: string]: string } {
    const relevantParams = Object.keys(params).filter(p => p.startsWith(`${this.queryParamPrefix}_`)).sort()
    const withValues = relevantParams.reduce((a, b) => ({
      ...a,
      [b]: params[b] // TODO remove prefix
    }), {})
    return withValues
  }

  parseQueryParams(params: Params) {
    const relevantParams = this.relevantQueryParams(params)

    this.first = relevantParams[`${this.queryParamPrefix}_page`] ? (parseInt(relevantParams[`${this.queryParamPrefix}_page`]) - 1) * this.size : 0
    this.searchTerm = relevantParams[`${this.queryParamPrefix}_searchTerm`] || ''
    const filter = relevantParams[`${this.queryParamPrefix}_filter`] || null
    const groupBy = relevantParams[`${this.queryParamPrefix}_groupby`] || null

    const parsedFilter = filter ? JSON.parse(filter) : null;
    for (const column of this.options.columns) {
      if (column.filter) {
        const filterSetting = this.filterSettings?.find(f => f.path === column.filter!.path)
        
        if (!filterSetting) continue

        const condition = parsedFilter?.conditions.find((condition: any) => condition.path === filterSetting.path);
        if (condition) {
          filterSetting.condition = JSON.parse(JSON.stringify(condition));
        } else {
          if (this.conditionService.isConditionWithStringValues(filterSetting.condition)) {
            filterSetting.condition.values = []
          } else if (this.conditionService.isConditionWithStringValue(filterSetting.condition)) {
            filterSetting.condition.value = ''
          }
        }
      }
    }

    if (this.id && !groupBy) {
      this.currentGroupByColumns.forEach(aggr => aggr.include = false);
      this.currentGroupByColumns = [];
      this.setGroupBySettings();
    } else if (this.id && groupBy) {
      const groupByColumnSettings: GroupByColumnSetting[] = []
      try {
        let groupParams: string[] = JSON.parse(groupBy);
        for (const groupByColumn of groupParams) {
          const column = this.options.columns.find(col => col.filter?.path === groupByColumn);
          if (column) {
            const index = this.options.columns.indexOf(column);
            groupByColumnSettings.push(this.groupByColumnSettings?.find(col => col.originalIndex === index)!);
          }
        }
      } catch {
        console.warn('Old or invalid URL query provided, groupby-query will be ignored. Please do not use that query anymore.');
      } finally {
        groupByColumnSettings.forEach(col => col.include = true);
        this.setGroupBySettings(groupByColumnSettings);
      }
    }

    this.setCurrentFilters(this.filterSettings?.filter(s => this.validCondition(s.condition)))

    this.lastQueryParamsHash = this.queryParamsHash(params)

    this.refresh()
  }

  queryParamsHash(params: Params): string {
    return md5(JSON.stringify(this.relevantQueryParams(params)));
  }

  setQueryParams(first?: number) {
    if (typeof first !== 'undefined') {
      this.first = first
    }

    const params: Params = {
      [`${this.queryParamPrefix}_searchTerm`]: this.searchTerm || null,
      [`${this.queryParamPrefix}_page`]: this.pageIndex > 0 ? this.pageIndex + 1 : null,
    }
    
    const currentFilter = this.currentFilter();
    params[`${this.queryParamPrefix}_filter`] = currentFilter ? JSON.stringify(currentFilter) : null;
    params[`${this.queryParamPrefix}_groupby`] = this.currentGroupByColumns && this.currentGroupByColumns.length > 0
      ? JSON.stringify(this.currentGroupByColumns.map(c => this.options.columns[c.originalIndex].filter?.path))
      : null;

    this.router.navigate([], {
      queryParams: params,
      queryParamsHandling: 'merge'
    })
  }

  paginate(event: any) {
    this.setQueryParams(event.first);
  }

  openColumnSettings() {
    this.availableColumns = JSON.parse(JSON.stringify(this.columnSettings));
    this.availableColumns = this.availableColumns?.filter(column => !column.visible)!;
    this.filteredColumns = this.availableColumns;
    this.chosenColumns = JSON.parse(JSON.stringify(this.currentColumns));
    this.showColumnSettingsEdit = true

    this.dragDropListHandler = new DragDropListHandler(this.chosenColumns || [], this.columnDragElements!);
  }

  removeColumn(column: ColumnSetting) {
    column.visible = false;
    this.availableColumns?.push(column);
    this.chosenColumns!.splice(this.chosenColumns!.indexOf(column), 1);
    
    this.resetColumnSearch();
  }

  addColumn(column: ColumnSetting) {
    column.visible = true;
    this.chosenColumns!.push(column);
    this.availableColumns?.splice(this.availableColumns.indexOf(column), 1);
    
    this.resetColumnSearch();
  }

  resetColumnSearch() {
    if (this.searchColumnsBox) {
      this.searchColumnsBox.nativeElement.value = '';
    }
    this.searchColumns('');
  }

  saveColumnsSettings() {
    if (this.chosenColumns) {
      this.currentColumns = this.chosenColumns;
      this.availableColumns = null;
      this.columnSettingsEdit = null;
      this.columnSettings?.forEach(column => column.visible = this.chosenColumns!.some(chosen => chosen.hash === column.hash));
      if (this.currentGroupByColumns.length === 0) {
        // We do not want to change the displayed columns if we are in aggregate view mode.
        this.columns = this.chosenColumns!.filter(c => c.visible).map((c) => c.originalIndex);
        this.chosenColumns = this.chosenColumns!.filter(c => c.visible);
      }
      this.columnSettingsEdit = null
      this.showColumnSettingsEdit = false

      if (this.id) {
        localStorage.setItem(this.localStorageColumnsKey, JSON.stringify(this.currentColumns))
      }
    }
  }

  async openFilterSettings() {
    this.filterSettingsEdit = JSON.parse(JSON.stringify(this.filterSettings))
    this.filteredFilters = this.filterSettingsEdit;

    for (const filterSetting of this.filterSettingsEdit || []) {
      if (!this.filterSuggestions[filterSetting.path]) {
        const column = this.options.columns.find(c => c.filter?.path === filterSetting.path)

        if (column?.filter?.values) {
          this.filterSuggestions[filterSetting.path] = await Promise.resolve(column.filter.values)
        }
      }
    }

    this.showFilterSettingsEdit = true
  }

  saveFiltersSettings() {
    if (this.filterSettingsEdit) {
      this.filterSettings = this.filterSettingsEdit
      this.filterSettingsEdit = null
      this.showFilterSettingsEdit = false
      this.setCurrentFilters(this.filterSettings.filter(s => this.validCondition(s.condition)))

      this.setQueryParams(0)
    }
  }

  clearFilter(filter?: FilterSetting) {
    if (filter) {
      if (this.conditionService.isConditionWithStringValue(filter.condition)) {
        filter.condition.value = ''
      }
      if (this.conditionService.isConditionWithStringValues(filter.condition)) {
        filter.condition.values = []
      }
      if (this.conditionService.isConditionWithDateValue(filter.condition)) {
        filter.condition.value = '';
      }
    } else if (this.filterSettings) {
      for (const filter of this.filterSettings) {
        if (this.conditionService.isConditionWithStringValue(filter.condition)) {
          filter.condition.value = ''
        }
        if (this.conditionService.isConditionWithStringValues(filter.condition)) {
          filter.condition.values = []
        }
        if (this.conditionService.isConditionWithDateValue(filter.condition)) {
          filter.condition.value = '';
        }
      }
    }

    this.setCurrentFilters((this.filterSettings || []).filter(s => this.validCondition(s.condition)))
    this.setQueryParams(0)
  }

  setCurrentFilters(filters?: FilterSetting[]) {
    this.currentFilters = filters || []

    if (this.id) {
      if (this.currentFilters.length > 0) {
        localStorage.setItem(this.localStorageFiltersKey, JSON.stringify(this.currentFilters))
      } else {
        localStorage.removeItem(this.localStorageFiltersKey)
      }
    }
  }

  conditionTypeChange(filter: FilterSetting, newConditionType: ConditionType) {
    const newCondition = {
      type: newConditionType,
      path: (filter.condition as any).path
    } as Condition

    if (this.conditionService.isConditionWithStringValue(newCondition) && this.conditionService.isConditionWithStringValue(filter.condition)) {
      newCondition.value = filter.condition.value || ''
    } else if (this.conditionService.isConditionWithStringValue(newCondition) && this.conditionService.isConditionWithStringValues(filter.condition)) {
      newCondition.value = filter.condition.values[0] || ''
    } else if (this.conditionService.isConditionWithStringValues(newCondition) && this.conditionService.isConditionWithStringValue(filter.condition)) {
      newCondition.values = filter.condition.value ? [filter.condition.value] : []
    } else if (this.conditionService.isConditionWithStringValues(newCondition) && this.conditionService.isConditionWithStringValues(filter.condition)) {
      newCondition.values = filter.condition.values || []
    }

    filter.condition = newCondition
  }

  openGroupBySettings() {
    this.availableGroupByColumns = JSON.parse(JSON.stringify(this.groupByColumnSettings));
    this.availableGroupByColumns = this.availableGroupByColumns?.filter(groupByColumn => !groupByColumn.include)!;
    this.filteredGroupColumns = this.availableGroupByColumns;
    this.chosenGroupByColumns = JSON.parse(JSON.stringify(this.currentGroupByColumns));
    this.showGroupByColumnSettingsEdit = true;

    this.dragDropListHandler = new DragDropListHandler(this.chosenGroupByColumns || [], this.groupByDragElements!);
  }

  removeGroupByColumn(groupByColumn: GroupByColumnSetting) {
    groupByColumn.include = false;
    this.availableGroupByColumns?.push(groupByColumn);
    this.chosenGroupByColumns!.splice(this.chosenGroupByColumns!.indexOf(groupByColumn), 1);
    
    this.resetGroupByColumnSearch();
  }

  addGroupByColumn(groupByColumn: GroupByColumnSetting) {
    groupByColumn.include = true;
    this.chosenGroupByColumns!.push(groupByColumn);
    this.availableGroupByColumns?.splice(this.availableGroupByColumns.indexOf(groupByColumn), 1);
    
    this.resetGroupByColumnSearch();
  }

  resetGroupByColumnSearch() {
    if (this.searchGroupByBox) {
      this.searchGroupByBox.nativeElement.value = '';
    }
    this.searchGroupByColumns('');
  }

  saveGroupBySettings() {
    if (this.chosenGroupByColumns) {
      this.availableGroupByColumns = null;
      this.groupByColumnSettingsEdit = null;
      this.showGroupByColumnSettingsEdit = false;
      this.setGroupBySettings(this.chosenGroupByColumns);

      this.setQueryParams(0);
    }

    this.dragDropListHandler = null;
  }

  setGroupBySettings(groupByColumns?: GroupByColumnSetting[]) {
    this.currentGroupByColumns= groupByColumns || []

    if (this.id) {
      if (this.currentGroupByColumns.length > 0) {
        localStorage.setItem(this.localStorageGroupColumnsKey, JSON.stringify(this.currentGroupByColumns));
      } else {
        localStorage.removeItem(this.localStorageGroupColumnsKey);
      }
    }

    if (this.currentGroupByColumns.length > 0) {
      if (this.isTileView) {
        this.toggleTileView();
      }
      this.columns = this.currentGroupByColumns.map(groupByColumn => groupByColumn.originalIndex);
    } else {
      this.columns = this.currentColumns?.filter(c => c.visible).map((c) => c.originalIndex) || [];
    }
  }

  clearGroupBySettings(groupByColumn?: GroupByColumnSetting) {
    if (groupByColumn) {
      const index = this.currentGroupByColumns?.indexOf(groupByColumn);
      if (index >= 0) {
        this.currentGroupByColumns[index].include = false;
        this.currentGroupByColumns?.splice(index);
      }
    } else {
      this.groupByColumnSettings?.forEach(groupByColumn => groupByColumn.include = false);
      this.currentGroupByColumns.forEach(aggr => aggr.include = false);
      this.currentGroupByColumns = [];
    }

    this.setGroupBySettings((this.groupByColumnSettings || []).filter(groupByColumn => groupByColumn.include));
    this.setQueryParams(0);
  }

  toggleTileView() {
    this.tileView = !this.tileView
    localStorage.setItem(this.localStorageTileViewKey, this.tileView ? 'true' : 'false')
    this.cdref.detectChanges()
  }

  onSelectedChange($event: boolean, index: number) {
    if ($event) {
      this.lastSelected = this.rows![index]
      this.selected.push(this.rows![index])

      if (this.shiftPressed && this.selectionPreview.length > 0) {
        const selectionPreview = this.selectionPreview.filter(p => p !== this.rows![index] && this.selected.indexOf(p) < 0)
        this.selected.push(...selectionPreview)
      }

    } else {
      this.lastSelected = null
      this.selected.splice(this.selected.indexOf(this.rows![index]), 1);
    }

    if (this.selected.length === this.rows?.length) {
      this.selectAllValue = true;
    } else {
      this.selectAllValue = (this.selected.length === 0 ? null : false);
    }

    this.selectedChange.emit(this.selected)
  }

  deselectAll() {
    this.selected = []
    this.lastSelected = null
    this.selectAllValue = false
  }

  onSelectAllValueChange($event: boolean | null) {
    this.lastSelected = null

    if ($event === false) {
      this.selectAllValue = null;
    }

    if (this.selectAllValue === null) {
      this.selected = [];
    } else if (this.selectAllValue === true) {
      this.selected = [...this.rows!];
    }

    this.selectedChange.emit(this.selected)
  }

  currentFilter(): AndCondition | null {
    const conditions = this.currentPredefinedFilter ? [this.currentPredefinedFilter] : this.currentFilters.map(s => s.condition);
    return conditions.length > 0 ? {
      type: 'And',
      conditions: conditions
    } as AndCondition : null;
  }

  searchFilters(term: string) {
    if (term === '') {
      this.filteredFilters = this.filterSettingsEdit!;
      return;
    }

    const options: fuzzy.FilterOptions<FilterSetting> = {
      extract: (filter: FilterSetting) => filter.header,
    };

    const results = fuzzy.filter(term, this.filterSettingsEdit!, options);
    this.filteredFilters = results.map(res => res.original);
  }

  searchColumns(term: string) {
    if (term === '') {
      this.filteredColumns = this.availableColumns;
      return;
    }

    const options: fuzzy.FilterOptions<ColumnSetting> = {
      extract: (column: ColumnSetting) => this.options.columns[column.originalIndex].header || '',
    };

    const results = fuzzy.filter(term, this.availableColumns!, options);
    this.filteredColumns = results.map(res => res.original);
  }

  searchGroupByColumns(term: string) {
    if (term === '') {
      this.filteredGroupColumns = this.availableGroupByColumns;
      return;
    }

    const options: fuzzy.FilterOptions<GroupByColumnSetting> = {
      extract: (groupByColumn: GroupByColumnSetting) => this.options.columns[groupByColumn.originalIndex]!.header || '',
    };

    const results = fuzzy.filter(term, this.availableGroupByColumns!, options);
    this.filteredGroupColumns = results.map(res => res.original);
  }

  tileHover(index: number) {
    this.lastHovered = this.rows![index]

    if (this.shiftPressed && this.lastSelected) {
      const lastIndex = this.rows?.indexOf(this.lastSelected) ?? -1

      if (index > lastIndex) {
        this.selectionPreview = this.rows?.slice(lastIndex, index + 1) || []
      } else if (lastIndex > index) {
        this.selectionPreview = this.rows?.slice(index, lastIndex + 1) || []
      }
    }
  }
}
