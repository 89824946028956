import { Injectable } from '@angular/core';
import { UserApiService } from 'projects/api/src/api';
import { AgencyService } from './agency.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RbacService {

  // short time cache, which prevents the loading on the same site multiple times
  private _cache: {[resource: string]: {[right: string]: Promise<boolean>}} = {}

  constructor(
    private userApiService: UserApiService,
    private agencyService: AgencyService,
    private authService: AuthService,
  ) { }

  /**
   * Returns true if the user has ONE OF the rights
   * @param right 
   * @param resource 
   */
  public async hasRight(right: string | string[], resource?: string) {
    const agencyResource = `/agencies/${this.agencyService.currentSelectedAgencyId!}${resource || ''}`  
    const rights = Array.isArray(right) ? right : [right]

    if (this._cache[agencyResource]) {
      if ((await Promise.all(rights.map(r => this._cache[agencyResource][r]))).some(r => r)) return true
    } else {
      this._cache[agencyResource] = {}
    }

    const rightsToResolve: {[right: string]: (hasRight: boolean) => void} = {}

    for (const r of rights) {
      this._cache[agencyResource][r] = new Promise<boolean>((resolve: (hasRight: boolean) => void) => {
        rightsToResolve[r] = resolve
      })
    }

    try {
  
      const [hasRights, status, err] = await this.userApiService.getUserHasRights(this.authService.userId, {
        rights: rights,
        resource: agencyResource
      })

      for (const r of rights) {
        rightsToResolve[r](hasRights?.rights[r] || false)

        setTimeout(() => {
          delete this._cache[agencyResource][r]
          if (Object.keys(this._cache[agencyResource]).length === 0) delete this._cache[agencyResource]
        }, 5000)
      }      

      return rights.some(r => hasRights?.rights[r])

    } catch (err) {
      console.error(err)
    }

    return false
  }

}
