import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StarRatingComponent } from './star-rating.component';


@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    StarRatingComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    StarRatingComponent
  ]
})
export class StarRatingModule { }
