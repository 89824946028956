import { Directive, ElementRef, OnInit } from "@angular/core";

@Directive({
  selector: "[autoFocus]"
})
export class AutoFocusDirective implements OnInit {
  private element: HTMLElement;

  constructor(private elementRef: ElementRef) {
    this.element = this.elementRef.nativeElement;
  }

  ngOnInit(): void {
    this.element.focus();
  }
}
