import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { PanelComponent, PanelsComponent } from './panels.component';
import { TabViewModule } from 'primeng/tabview';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    PanelsComponent,
    PanelComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TabViewModule,
    FlexLayoutModule,
  ],
  exports: [
    PanelsComponent,
    PanelComponent
  ]
})
export class PanelsModule { }
