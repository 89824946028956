import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterDeleted'
})
export class FilterDeletedPipe implements PipeTransform {

  transform<T extends {deletedAt?: Date | string | null}[] | undefined>(values: T, disabled?: boolean): T {
    if (disabled || !values) return values
    return values.filter(v => !v.deletedAt) as T;
  }

}
