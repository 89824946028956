import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StateBarComponent } from './state-bar.component';
import { TooltipModule } from 'primeng/tooltip';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    StateBarComponent
  ],
  imports: [
    CommonModule,
    TooltipModule,
    RouterModule,
  ],
  exports: [
    StateBarComponent
  ]
})
export class StateBarModule { }
