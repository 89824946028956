import { Injectable } from '@angular/core';
import { AssetTask, GetSubtasksQuery, GetTaskAttachmentsQuery, GetTaskCommentsQuery, GetTaskQuery, GetTaskSubtasksQuery, GetTasksQuery, PostTaskAttachmentsBody, PostTaskAttachmentsQuery, PostTaskBody, PostTaskCommentsBody, PostTaskStateBody, PostTaskSubtaskBody, PostTaskSubtasksBody, PostTaskSubtasksQuery, Subtask, Task, TaskApiService, TaskAttachmentFile } from 'projects/api/src/api';
import { Observable } from 'rxjs';
import { AgencyService } from './agency.service';
import { UploadService } from './upload.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  private _activeTaskAttachmentUploads: {[task: string]: {
    taskAttachment: TaskAttachmentFile,
    progress: Observable<number | 'Finished' | 'Error'>,
  }[]} = {}

  constructor(
    private taskApiService: TaskApiService,
    private agencyService: AgencyService,
    private uploadService: UploadService,
  ) { }

  public getTasks(query: Omit<GetTasksQuery, 'agency'>) {
    return this.taskApiService.getTasks({
      agency: this.agencyService.currentSelectedAgencyId!,
      ...query
    })
  }

  public getSubtasks(query: GetSubtasksQuery) {
    return this.taskApiService.getSubtasks(query);
  }

  public getTaskSubTasks(taskId: string, query: Omit<GetTaskSubtasksQuery, 'agency'>) {
    return this.taskApiService.getTaskSubtasks(taskId, {
      agency: this.agencyService.currentSelectedAgencyId!,
      ...query
    })
  }

  public getTaskAttachments(taskId: string, query?: GetTaskAttachmentsQuery) {
    return this.taskApiService.getTaskAttachments(taskId, query)
  }

  public getTaskAttachmentDownload(taskId: string, taskAttachmentId: string) {
    return this.taskApiService.getTaskAttachmentDownload(taskId, taskAttachmentId)
  }

  public getTaskComments(taskId: string, query: GetTaskCommentsQuery) {
    return this.taskApiService.getTaskComments(taskId, query)
  }

  public getTask(taskId: string, query: GetTaskQuery) {
    return this.taskApiService.getTask(taskId, query)
  }

  public postTaskComments(taskId: string, body: PostTaskCommentsBody) {
    return this.taskApiService.postTaskComments(taskId, body)
  }

  public postTaskState(taskId: string, body: PostTaskStateBody) {
    return this.taskApiService.postTaskState(taskId, body)
  }

  public isAssetTask(task: Task): task is AssetTask {
    return task.type === 'AssetTask'
  }

  // public taskName(task: Task) {
  //   if (this.isAssetTask(task)) return `Changes on ${task.asset$?.assetType} ${task.asset$?.displayName || '<unknown>'}`
  //   if (task.name) return task.name
  //   return '<unknown>'
  // }

  public async createTaskAttachment(task: Task, body: PostTaskAttachmentsBody, query: PostTaskAttachmentsQuery, file: File) {
    const [ taskAttachment ] = await this.taskApiService.postTaskAttachments(task._id, body, query)

    if (taskAttachment) {

      if (!this._activeTaskAttachmentUploads[task._id]) this._activeTaskAttachmentUploads[task._id] = []

      const progress = this.uploadService.upload(taskAttachment.signedURL, file, {
        type: 'TaskAttachment',
        fileName: file.name,
        size: file.size,
        routerLink: ['/tasks', task._id],
        description: `For task ${task.name}`,
        task: task._id,
        taskAttachment: taskAttachment.taskAttachment._id,
      })

      this._activeTaskAttachmentUploads[task._id].push({
        taskAttachment: taskAttachment.taskAttachment,
        progress: progress
      })

      const subscription = progress.subscribe(async (status) => {
        if (status === 'Finished' || status === 'Error') {

          if (status === 'Finished') await this.emitTaskAttachmentFinished(task._id, taskAttachment.taskAttachment._id)
          if (status === 'Error') await this.emitTaskAttachmentError(task._id, taskAttachment.taskAttachment._id)

          subscription.unsubscribe()

          this._activeTaskAttachmentUploads[task._id] = this._activeTaskAttachmentUploads[task._id].filter(t => {
            return t.taskAttachment._id !== taskAttachment.taskAttachment._id
          })

          if (this._activeTaskAttachmentUploads[task._id].length === 0) {
            delete this._activeTaskAttachmentUploads[task._id]
          }
        }
      })

      return taskAttachment.taskAttachment
    } else {
      return null
    }
  }

  public deleteTaskAttachment(taskId: string, attachmentId: string) {
    return this.taskApiService.postTaskAttachmentDelete(taskId, attachmentId);
  }

  private emitTaskAttachmentError(taskId: string, taskAttachmentId: string) {
    return this.taskApiService.postTaskAttachmentError(taskId, taskAttachmentId)
  }

  private emitTaskAttachmentFinished(taskId: string, taskAttachmentId: string) {
    return this.taskApiService.postTaskAttachmentFinished(taskId, taskAttachmentId)
  }

  public activeTaskAttachmentUploads(taskId: string) {
    return this._activeTaskAttachmentUploads[taskId] || []
  }

  public assignSubtask(taskId: string, subtaskId: string, user: string | null, contract: string | null) {
    return this.taskApiService.postTaskSubtask(taskId, subtaskId, {
      assignee: user,
      contract: contract,
    })
  }

  public setSubtaskState(taskId: string, subtaskId: string, state: 'New' | 'InProgress' | 'Done') {
    return this.taskApiService.postTaskSubtask(taskId, subtaskId, {
      state: state,
    })
  }
  
  public updateTask(task: string, body: PostTaskBody) {
    return this.taskApiService.postTask(task, body)
  }

  public createSubtask(task: string, body: PostTaskSubtasksBody, query?: PostTaskSubtasksQuery) {
    return this.taskApiService.postTaskSubtasks(task, body, query)
  }
}
