import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssetTask, CreateVariantSetSubtaskContext, CreateVariantValueSubtaskContext, Subtask, Task, TaskAttachmentFile } from 'projects/api/src/api';
import { Observable, Subscription } from 'rxjs';
import { Breadcrumb } from '../../../components/header/header.component';
import { TableComponent } from '../../../components/table/table.component';
import { IListResponse, TableOptions, TableQuery } from '../../../components/table/table.interfaces';
import { AgencyService } from '../../../services/agency.service';
import { DownloadService } from '../../../services/download.service';
import { TaskService } from '../../../services/task.service';
import { Upload } from '../../../services/upload.service';
import { FormGroup, FormControl } from '@angular/forms';


@Component({
  template: `
    <span *ngIf="field === 'name' || field === 'assetVersionName'">
      <span *ngIf="subtask | as:'Subtask':'CreateAssetVersion' as s">
        <span *ngIf="field === 'name'">Create asset version</span>
        <span *ngIf="field === 'assetVersionName'">{{s.context.assetVersionName}}</span>
      </span>
      <span *ngIf="subtask | as:'Subtask':'CreateVariantSet' as s">
        <span *ngIf="field === 'name'">Create variant set <b>{{s.context.variantSet}}</b></span>
        <span *ngIf="field === 'assetVersionName'">{{s.context.assetVersionName}}</span>
      </span>
      <span *ngIf="subtask | as:'Subtask':'CreateVariantValue' as s">
        <span *ngIf="field === 'name'">Create variant value <b>{{s.context.variantValue}}</b> for variant set <b>{{s.context.variantSet}}</b></span>
        <span *ngIf="field === 'assetVersionName'">{{s.context.assetVersionName}}</span>
      </span>
      <span *ngIf="subtask | as:'Subtask':'CreateCamera' as s">
        <span *ngIf="field === 'name'">Create camera <b>{{s.context.camera}}</b></span>
        <span *ngIf="field === 'assetVersionName'">{{s.context.assetVersionName}}</span>
      </span>
    </span>
  `,
})
export class TaskDetailSubtaskComponent {
  subtask!: Subtask
  field: 'name' | 'assetVersionName' | 'orderLines' = 'name'
}

@Component({
  template: `
    <a *ngIf="mode === 'download'" (click)="download()" [attr.title]="taskAttachment.fileName">
      {{ taskAttachment.fileName }}
    </a>
    <span *ngIf="mode !== 'download'" [pTooltip]="taskAttachment.fileName!">
      {{ taskAttachment.fileName }}
      <span>
        <span *ngIf="mode === 'error'"><i class="pi pi-times-circle"></i></span>
        <span *ngIf="mode === 'progress'">
          <p-progressBar [value]="asNumber(progress | async) * 100" styleClass="inline small" [showValue]="false"></p-progressBar>
        </span>
      </span>
    </span>
  `,
})
export class TaskDetailAttachmentComponent implements OnInit, OnDestroy {
  taskAttachment!: TaskAttachmentFile
  progress: Observable<number | 'Finished' | 'Error'> | null = null
  mode: 'none' | 'download' | 'success' | 'error' | 'progress' = 'none'
  subscriptions: Subscription[] = []

  constructor(
    private taskService: TaskService,
    private downloadService: DownloadService,
  ) {}

  ngOnInit() {
    if (this.taskAttachment.state === 'Finished') {
      this.mode = 'download'
    } else {
      const upload = this.taskService.activeTaskAttachmentUploads(this.taskAttachment.task).find(t => t.taskAttachment._id === this.taskAttachment._id)

      if (upload) {
        this.progress = upload.progress
        this.mode = 'progress'

        this.subscriptions.push(this.progress.subscribe(p => {
          if (p === 'Finished') {
            setTimeout(() => {
              this.mode = 'download'
            }, 1000);
          } else if (p === 'Error') {
            this.mode = 'error'
          }
        }))
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  async download() {
    try {
      const [ download ] = await this.taskService.getTaskAttachmentDownload(this.taskAttachment.task, this.taskAttachment._id)

      if (download) {
        this.downloadService.download(download.downloadUrl)
      }
    } catch (err) {
      console.error(err)
    }
  }

  asNumber(progress: number | 'Finished' | 'Error' | null): number {
    if (progress === 'Finished') return 1
    if (typeof progress === 'number') return progress
    return 0
  }
}


@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailComponent implements OnInit {

  @ViewChild('attachmentsTable') attachmentsTable!: TableComponent<TaskAttachmentFile>

  errorLog: string = ''
  showError: boolean = false
  subtasksTableOptions: TableOptions<Subtask>
  attachmentsTableOptions: TableOptions<TaskAttachmentFile>
  breadcrumbs?: Breadcrumb[] = []
  title: string = ''
  taskId: string
  searchTerm: string = ''
  task: Task | null = null
  assetTask: AssetTask | null = null
  loading = true

  subtasks: Subtask[] = []
  isSubtaskSelected: {[id: string]: boolean} = {}
  showClosedSubtasks = false
  assignVisible = false

  tags: string[] = []

  newSubtaskVisible = false
  newSubtaskForm = new FormGroup({
    name: new FormControl(''),
    description: new FormControl(''),
    assignee: new FormControl(''),
    contract: new FormControl(''),
  })

  get selectedSubtasks(): Subtask[] {
    return this.subtasks.filter(s => this.isSubtaskSelected[s._id])
  }

  constructor(
    private taskService: TaskService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.taskId = this.activatedRoute.snapshot.params['taskId']

    this.subtasksTableOptions = {
      hideHeader: true,
      size: 99999,
      columns: [
        { name: 'type', header: 'Subtask', component: (subtask) => ({
          component: TaskDetailSubtaskComponent,
          data: {subtask, field: 'name'},
        }) },
        { name: 'type', header: 'Asset Version', component: (subtask) => ({
          component: TaskDetailSubtaskComponent,
          data: {subtask, field: 'assetVersionName'}
        }) },
        // { name: 'createdAt', header: 'Created At', format: 'date' },
        // { name: 'createdBy$', header: 'Created By', format: 'user' },
        { name: 'state', header: 'State' },
        { name: 'numberOfOrderLines', header: '# Order Lines', format: 'number', align: 'right' },
      ],
      defaultSort: 'identifier',
      defaultSortDirection: 'asc',
    }

    this.attachmentsTableOptions = {
      hideHeader: true,
      size: 99999,
      columns: [
        { name: 'fileName', header: 'Attachment', ellipsisMode: 'ellipsed', ellipsisWidth: '450px',
          component: (taskAttachment) => ({
            component: TaskDetailAttachmentComponent,
            data: { taskAttachment },
          })
        },
        // { name: 'createdAt', header: 'Created At', format: 'date' },
        { name: 'length', header: 'Size', format: 'fileSize' },
        { name: 'createdBy$', header: 'Uploaded By', format: 'user' },
        { name: 'createdAt', header: 'Uploaded At', format: 'date' },
        { name: 'dropdown', dropdown: [
          {
            label: 'Delete',
            icon: 'pi pi-times',
            action: async (row: TaskAttachmentFile): Promise<void> => {
              await this.taskService.deleteTaskAttachment(this.task?._id!, row._id);
              this.attachmentsTable.refresh();
            }
          }
        ]},
      ],
      defaultSort: 'identifier',
      defaultSortDirection: 'asc',
    }
  }

  async ngOnInit(): Promise<void> {
    await Promise.all([
      this.loadTask(),
      this.loadSubtasks()
    ])
  }

  async loadTask() {
    const [task] = await this.taskService.getTask(this.taskId, {lookups: 'asset,agency'})

    if (task) {
      this.task = task
      this.assetTask = null
      this.tags = (task.tags || []).filter(tag => !tag.deletedAt).map(tag => tag.value);

      if (this.taskService.isAssetTask(this.task)) {
        this.assetTask = this.task
        // this.title = this.taskService.taskName(this.task)
        this.title = this.task.name
      }

      this.breadcrumbs = [
        {title: 'Tasks', routerLink: ['/tasks']},
        {title: this.title},
      ]

    }

    this.loading = false
  }

  async loadSubtasks() {
    this.isSubtaskSelected = {}

    const [ subtasks ] = await this.taskService.getTaskSubTasks(this.taskId, {
      lookups: 'contract,assignee,createdBy',
      limit: 99999,
    })

    if (subtasks) {

      this.subtasks = subtasks.items.filter(s => s.state !== 'Done' || this.showClosedSubtasks).sort((a, b) => {
        if (a.type === 'CreateVariantSet' && b.type === 'CreateVariantValue') {
          const aContext = a.context as CreateVariantSetSubtaskContext
          const bContext = b.context as CreateVariantValueSubtaskContext

          const compareSet = (aContext.variantSet || '').localeCompare((bContext.variantSet || ''))
          return compareSet || -1
        }

        if (a.type === 'CreateVariantValue' && b.type === 'CreateVariantSet') {
          const aContext = a.context as CreateVariantValueSubtaskContext
          const bContext = b.context as CreateVariantSetSubtaskContext

          const compareSet = (aContext.variantSet || '').localeCompare((bContext.variantSet || ''))
          return compareSet || 1
        }

        if (a.type === 'CreateVariantSet' && b.type === 'CreateVariantSet') {
          const aContext = a.context as CreateVariantSetSubtaskContext
          const bContext = b.context as CreateVariantSetSubtaskContext

          const compareSet = (aContext.variantSet || '').localeCompare((bContext.variantSet || ''))
          if (compareSet !== 0) return compareSet
        }

        if (a.type === 'CreateVariantValue' && b.type === 'CreateVariantValue') {
          const aContext = a.context as CreateVariantValueSubtaskContext
          const bContext = b.context as CreateVariantValueSubtaskContext

          const compareSet = (aContext.variantSet || '').localeCompare((bContext.variantSet || ''))
          if (compareSet !== 0) return compareSet

          const compareValue = (aContext.variantValue || '').localeCompare((bContext.variantValue || ''))
          if (compareValue !== 0) return compareValue
        }

        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      })

      for (const subtask of this.subtasks) {
        this.isSubtaskSelected[subtask._id] = false
      }
    }
  }

  async queryAttachments(query: TableQuery<TaskAttachmentFile>) {
    query.result = new Promise<IListResponse<TaskAttachmentFile>>(async (resolve: (result: IListResponse<TaskAttachmentFile>) => void, reject: (err: any) => void) => {
      try {
        const uploading = this.taskService.activeTaskAttachmentUploads(this.taskId)

        const [ taskAttachments, _, err ] = await this.taskService.getTaskAttachments(this.taskId, {
          ...query.query,
          lookups: 'createdBy'
        })

        if (taskAttachments) {
          taskAttachments.items = taskAttachments.items.concat(uploading.map(u => u.taskAttachment))
          taskAttachments.totalCount += uploading.length
          resolve(taskAttachments)
        } else {
          throw err
        }
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }

  async uploadFiles(files: FileList | null) {
    if (!files || files.length === 0) return

    this.loading = true

    try {
      if (this.task) {
        const promises: Promise<any>[] = []

        for (let i = 0; i < files.length; i++) {
          const file = files.item(i);

          if (file) {
            promises.push(this.taskService.createTaskAttachment(this.task, {
              length: file.size,
              fileName: file.name,
              mimeType: file.type,
            }, {
              lookups: 'createdBy'
            }, file))
          }
        }

        await Promise.all(promises)

        this.attachmentsTable?.refresh({
          showLoading: false
        })
      }
    } catch (err) {
      console.error(err)
    }

    this.loading = false
  }

  async saveTags() {
    const [task] = await this.taskService.updateTask(this.taskId, {
      tags: this.tags
    })

    await this.loadTask()
  }

  async submitSubtask() {
    this.loading = true

    try {

      await this.taskService.createSubtask(this.taskId, {
        name: this.newSubtaskForm.get('name')?.value || '',
        description: this.newSubtaskForm.get('description')?.value || '',
        assignee: this.newSubtaskForm.get('assignee')?.value || null,
        contract: this.newSubtaskForm.get('contract')?.value || null,
      })

      await this.loadTask()

    } catch (err) {
      console.error(err)
    }

    this.newSubtaskVisible = false
    this.loading = false
  }
}
