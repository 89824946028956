import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubtasksComponent } from './subtasks.component';
import { HeaderModule } from "../../../components/header/header.module";
import { TableModule } from "../../../components/table/table.module";



@NgModule({
    declarations: [
        SubtasksComponent
    ],
    imports: [
        CommonModule,
        HeaderModule,
        TableModule
    ]
})
export class SubtasksModule { }
