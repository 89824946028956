import { AuthConfig } from 'angular-oauth2-oidc';

const showDebugInformation = window.location.origin.startsWith('https') ? false : true

export const authCodeFlowConfig: AuthConfig = {
  redirectUri: `${window.location.origin}/`,
  postLogoutRedirectUri: `${window.location.origin}/`,
  clientId: 'imagejet_customer',
  responseType: 'code',
  scope: 'openid profile offline_access',
  silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,
  useSilentRefresh: true,
  showDebugInformation: showDebugInformation,
  silentRefreshTimeout: 5000, // For faster testing
  timeoutFactor: 0.25 // For faster testing
  // clearHashAfterLogin: false // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040
};
