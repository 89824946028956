import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AssetTilesviewModule } from '../../components/assets/asset-tilesview/asset-tilesview.module';
import { FooterModule } from '../../components/footer/footer.module';
import { TopbarModule } from '../../components/topbar/topbar.module';
import { AssetsModule } from '../assets/assets.module';
import { HomeComponent } from './home.component';

const routes: Routes = [
  { path: '', component: HomeComponent},
];

@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    AssetTilesviewModule,
    RouterModule.forChild(routes),
  ],
  exports: [
    HomeComponent
  ]
})
export class HomeModule { }
