import { Component, Input, ContentChildren, QueryList, HostBinding } from "@angular/core";

@Component({
  selector: 'c-panel',
  template: '<ng-content></ng-content>',
})
export class PanelComponent {
  @Input() header: string = '';
  @HostBinding('class.hide') hidden: boolean = true;

  constructor() {}
}


@Component({
  selector: 'c-panels',
  templateUrl: './panels.component.html',
  styleUrls: ['./panels.component.scss']
})
export class PanelsComponent {
  @ContentChildren(PanelComponent) panels!: QueryList<PanelComponent>;

  @Input() @HostBinding("class")
  size: 'collapsed' | 'normal' | 'wide' = 'normal'

  selectedTab: number = 0;

  public get headers(): string[] {
    return this.panels.map(p => p.header)
  }

  constructor() { }

  ngAfterViewInit() {
    this.selectTab(0)
  }

  selectTab(index: number): void {
    this.selectedTab = index;
    this.panels.forEach((panel, i) => {
      panel.hidden = i !== index;
    });
  }

  increaseSize() {
    if (this.size === 'collapsed') {
      this.size = 'normal'
    } else if (this.size === 'normal') {
      this.size = 'wide'
    }
  }

  decreaseSize() {
    if (this.size === 'wide') {
      this.size = 'normal'
    } else if (this.size === 'normal') {
      this.size = 'collapsed'
    }
  }

  toggleSize(size: 'collapsed' | 'normal' | 'wide'): void {
    this.size = size;
  }
}
