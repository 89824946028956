<footer class="page-footer">
  <div class="footer-content">
    <div fxLayout="row" fxLayoutAlign="center center" class="footer-links">
      <a class="footer-menu-text" mat-button>
        Improve
      </a>
      <a class="footer-menu-text" mat-button>
        Data Privacy
      </a>
      <a class="footer-menu-text" mat-button>
        General condition of purchase
      </a>
      <a class="footer-menu-text" mat-button>
        Contact
      </a>
      <a class="footer-menu-text" mat-button>
        Customize cookie consent
      </a>
    </div>
  </div>
</footer>
