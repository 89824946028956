
<div class="options">
  <div class="asset-type">

  </div>
  <div class="search">
    <c-search-box [(searchTerm)]="searchTerm" type="box" (search)="query()"></c-search-box>
  </div>
  <div class="filter">

  </div>
</div>

<div class="assets">
  <div *ngFor="let asset of assets; trackBy:trackById; let index = index" 
    class="asset"
    [style.width]="widths[index]"
  >
    <a 
      [routerLink]="['/' + asset.agency$?.name + ':' + asset.name]"
      [style.backgroundImage]="'url(' + (asset?.previewSet | assetPreviewSetImage) + ')'"
    >
      <div class="metadata">
        <h3>{{asset.displayName}}</h3>
        <p>{{asset.agency$?.displayName}}</p>
      </div>
    </a>
  </div>
</div>



