import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'c-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {

  @ViewChild("searchBox") searchBox!: ElementRef;

  @Input() debounce = 1200
  @Input() searchTerm = ''
  @Input() placeholder = 'What are you looking for?'
  @Input() @HostBinding('class') type: 'light' | 'box' | 'none' = 'light'

  @Output() searchTermChange: EventEmitter<string> = new EventEmitter<string>()
  @Output() search: EventEmitter<string> = new EventEmitter<string>()

  subscription!: Subscription

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const observable = fromEvent(this.searchBox.nativeElement, 'keyup');
    this.subscription = observable.pipe(debounceTime(this.debounce)).subscribe(c => {
      this.executeSearch()
    });
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe()
  }

  searchTermChanged(searchTerm: string) {
    this.searchTerm = searchTerm
    this.searchTermChange.emit(this.searchTerm)
  }

  executeSearch() {
    this.search.emit(this.searchTerm)
  }
}

