<div>
  <c-header header="Subtasks" [breadcrumbs]="breadcrumbs"></c-header>
</div>

<c-table 
  #subtasksTable
  *ngIf="subtasksTableOptions" 
  header="Subtasks"
  [id]="'task_subtasks'"
  [options]="subtasksTableOptions" 
  (query)="querySubtasks($event)"
></c-table>