import { Pipe, PipeTransform } from "@angular/core"
import { Job } from "projects/api/src/api"
import { Observable, combineLatest, map, of, startWith } from "rxjs"
import { JobService } from "../services/job.service"
import { State } from "../components/progress-circle/progress-circle.component"

@Pipe({
  name: 'stepStateMapper'
})
export class StepStateMapperPipe implements PipeTransform {

  constructor(
    private jobService: JobService,
  ) {}

  transform(job: undefined | null | Job, ...[stepType, stepHash]: [string, string]): Observable<{state: State, percentage: number, pause: boolean}> {
    const id = typeof job === 'string' ? job : job?._id

    if (!id) return of({
      state: 'Unknown',
      percentage: 0,
      pause: false,
    })

    return combineLatest([
      typeof job === 'string' ? this.jobService.getJob(job).then(([j]) => j) : Promise.resolve(job),
      this.jobService.register(id).pipe(startWith(null)),
      this.jobService.progress(id).pipe(startWith(null)),
    ]).pipe(map(([current, latest, progress]) => {

      const job = latest || current
      const executionPlan = job?.executionPlan || []
      const index = executionPlan.findIndex(e => e.stepType === stepType && e.hash === stepHash)
      const pauseIndex = job?.pauseAt ? executionPlan.findIndex(e => e.stepType === job.pauseAt?.stepType && e.hash === job.pauseAt.hash) : -1
      const execution = executionPlan[index || 0]

      let state: State = 'Unknown'

      if (execution?.error) state = 'Error'
      else if (execution?.finishedAt) state = 'Success'
      else if (index === progress?.stepIndex && job?.state === 'Running') state = 'Progress'
      else if (job?.state !== 'Paused' && executionPlan.find((e, i) => index > i) && (pauseIndex === -1 || index <= pauseIndex)) state = 'Waiting'
      else if (job?.state === 'Queued' && index === 0) state = 'Waiting'
      
      return {
        state: state,
        percentage: progress?.stepProgress || 0,
        pause: index != -1 && index === pauseIndex,
      }
    }))
  }
}