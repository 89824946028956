import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Asset } from 'projects/api/src/api';
import { AssetService } from '../../services/asset.service';
import { AgencyService } from '../../services/agency.service';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {
  baseName!: string
  asset: Asset | null = null
  loading = true
  showNotFound = false
  ownAsset = false

  constructor(
    private activatedRoute: ActivatedRoute,
    private assetService: AssetService,
    private agencyService: AgencyService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.baseName = this.activatedRoute.snapshot.params['baseName']

    const [asset, statusCode] = await this.assetService.get(this.baseName)

    this.ownAsset = asset?.agency === this.agencyService.currentSelectedAgencyId
    this.asset = asset
    this.showNotFound = statusCode === 404
    this.loading = false
  }

}
