<c-header header="Task" [subheader]="title" [breadcrumbs]="breadcrumbs" [(tags)]="tags" (tagsChange)="saveTags()">
  <div actions>
    <button type="button" (click)="newSubtaskVisible = true"><i class="pi pi-plus"></i>New Subtask</button>
    <button type="button" (click)="fileInput.click()"><i class="pi pi-upload"></i>Upload Attachment</button>
  </div>
</c-header>

<input hidden type="file" name="file" #fileInput (change)="uploadFiles(fileInput.files)" multiple />

<div class="taskdetails">

  <div>
    <div class="panel">
      <div class="header">Description</div>
      <div class="body" *ngIf="assetTask">
        {{ title }}
        <br /><br />
        <a [routerLink]="['/assets', assetTask.asset]" target="_blank">Open Asset <i class="pi pi-external-link"></i></a>
        <br /><br />
        <i>This is an automatically created task</i>
      </div>
    </div>
    <div class="panel">
      <div class="header">Comments</div>
      <c-comment [task]="taskId"></c-comment>
    </div>
  </div>

  <div>
    <div class="panel" *ngIf="task">
      <div class="header">
        <div>Subtasks</div>
        <div>
          <input type="checkbox" [(ngModel)]="showClosedSubtasks" (ngModelChange)="loadSubtasks()" />
          Show closed subtasks ({{ task.subtasksStatistic.done || 0 }})
        </div>
      </div>
      <c-subtask *ngFor="let subtask of subtasks" 
        [task]="task"
        [subtask]="subtask" 
        [(selected)]="isSubtaskSelected[subtask._id]" 
        [showSelection]="selectedSubtasks.length > 0" 
        (changed)="loadSubtasks()"
      ></c-subtask>
    </div>
    <div class="panel">
      <c-table #attachmentsTable [options]="attachmentsTableOptions" (query)="queryAttachments($event)"></c-table>
    </div>
  </div>

</div>

<p-sidebar [(visible)]="newSubtaskVisible" position="right">
  <ng-template pTemplate="header">New Subtask</ng-template>
  
  <form [formGroup]="newSubtaskForm" (submit)="submitSubtask()">
    <div class="input">
      <label for="name">Name</label>
      <input type="text" formControlName="name" />
    </div>
    <div class="input">
      <label for="description">Description</label>
      <textarea formControlName="description"></textarea>
    </div>
    
    <button type="submit">Create</button>
  </form>
</p-sidebar>

<app-error-snackbar [errorMessage]="errorLog" [visible]="showError"></app-error-snackbar>

<p-sidebar [visible]="selectedSubtasks.length > 0 && !assignVisible" position="bottom" [modal]="false" [showCloseIcon]="false">
  <div class="subtasks-actions">
    {{selectedSubtasks.length}} Selected Subtasks
    <button (click)="assignVisible = true">Assign</button>
  </div>
</p-sidebar>

<p-sidebar [(visible)]="assignVisible" position="right">
  <ng-template pTemplate="header">Assign</ng-template>
  <c-subtask-assign *ngIf="assignVisible" [task]="task!" [subtasks]="selectedSubtasks" (changed)="loadSubtasks(); assignVisible = false"></c-subtask-assign>
</p-sidebar>