import { Pipe, PipeTransform } from "@angular/core"
import { Job } from "projects/api/src/api"
import { State } from "../components/progress-circle/progress-circle.component"

@Pipe({
  name: 'jobStateMapper'
})
export class JobStateMapperPipe implements PipeTransform {

  constructor() {}

  transform(job?: Job, ...args: unknown[]): State {
    if (job?.state === 'Canceled') return 'Canceled'
    if (job?.state === 'Error') return 'Error'
    if (job?.state === 'Success') return 'Success'
    if (job?.state === 'Paused') return 'Paused'
    if (job?.state === 'Running') return 'Progress'
    return 'Loading'
  }

}