<div class="stat"
  [pTooltip]="tooltip" 
  [escape]="false" 
  tooltipStyleClass="tooltip-wide states"
>
  <div class="value">{{ stateValue | number }}</div>
  <div class="bar">
    <div *ngFor="let bar of bars; let index = index" 
      class="bar-value" 
      [style.width]="bar.width" 
      [style.background-color]="bar.color" 
      [style.zIndex]="bars.length - index"
    ></div>
  </div>
</div>