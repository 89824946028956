<div class="table-header" *ngIf="!options.hideHeader">
  <div class="title" *ngIf="header">{{ header }} <span class="rows" *ngIf="!loading && totalCount !== null">{{totalCount}} Rows</span></div>
  <div class="search">
    <c-search-box *ngIf="options.search" [(searchTerm)]="searchTerm" [placeholder]="options.searchPlaceholder || 'Search in table ...'" type="none" (search)="setQueryParams(0)"></c-search-box>
  </div>
  <div class="buttons">
    <p-button *ngIf="groupByColumnSettings && groupByColumnSettings.length > 0" (onClick)="openGroupBySettings();" icon="pi pi-window-minimize" label="Aggregate"></p-button>
    <p-button *ngIf="filterSettings && filterSettings.length > 0" (onClick)="openFilterSettings();" icon="pi pi-filter" label="Filter"></p-button>
    <p-button *ngIf="!options.subRow && !options.hideColumnOptions && !tileView && currentGroupByColumns.length === 0" (onClick)="openColumnSettings();" icon="pi pi-table" label="Columns"></p-button>
    <p-button *ngIf="options.tileView && currentGroupByColumns.length === 0" (onClick)="toggleTileView()" icon="pi pi-table" [label]="tileView ? 'Table View' : 'Tile View'"></p-button>
    <p-button *ngFor="let additionalButton of options.additionalButtons" (onClick)="additionalButton.action()" [icon]="additionalButton.icon || 'pi'" [label]="additionalButton.label"></p-button>
  </div>
</div>

<div class="table-filters" *ngIf="currentFilters.length > 0">
  <div class="filters">
    Filtered by:
    <p-chip *ngFor="let filter of currentFilters" [removable]="true" (onRemove)="clearFilter(filter)">
      <span class="field">{{ filter.header }}</span>
      <span class="operator">{{ filter.condition.type | splitByUpperCase | lowercase }}</span>
      <span class="value" *ngIf="filter.condition | as:'Condition':'WithStringValue' as condition">{{ condition.value }}</span>
      <span class="value" *ngIf="filter.condition | as:'Condition':'WithStringValues' as condition">{{ condition.values.join(', ') }}</span>
    </p-chip>
  </div>
  <button pButton icon="pi pi-times"  class="p-button-warning" (click)="clearFilter()" label="Clear"></button>
</div>
<div class="table-filters" *ngIf="currentGroupByColumns.length > 0">
  <div class="filters group-columns">
    Grouped by:
    <p-chip *ngFor="let groupByColumn of currentGroupByColumns" [removable]="true" (onRemove)="clearGroupBySettings(groupByColumn)">
      <span class="field">{{ options.columns[groupByColumn.originalIndex].header || '' }}</span>
    </p-chip>
  </div>
  <button pButton icon="pi pi-times"  class="p-button-warning" (click)="clearGroupBySettings()" label="Clear"></button>
</div>

<div class="table-loading" *ngIf="loading">
  <i class="pi pi-spin pi-spinner"></i>
</div>

<div class="table-body" *ngIf="!loading">
  <table *ngIf="!tileView && currentGroupByColumns.length === 0">
    <thead>
      <tr>
        <th class="selector" *ngIf="options.selectable">
          <p-triStateCheckbox [checkboxFalseIcon]="fuzzyIcon" [(ngModel)]="selectAllValue" (ngModelChange)="onSelectAllValueChange($event)"></p-triStateCheckbox>
        </th>
        <th *ngFor="let column of currentColumns"
            [style.text-align]="options.columns[column.originalIndex].align || 'left'"
            [style.max-width]="options.columns[column.originalIndex].ellipsisMode === 'ellipsed' ? options.columns[column.originalIndex].ellipsisWidth : 'auto'">
          {{ options.columns[column.originalIndex].header }}
        </th>
        <th *ngIf="options.routerLink"></th>
      </tr>
    </thead>
    <ng-container *ngFor="let row of rows; let odd = odd; let i = index">
      <tbody>
        <tr 
          c-table-row 
          [class.odd]="odd" 
          [options]="options" 
          [columns]="columns" 
          [row]="row" 
          [selected]="selected.indexOf(row) >= 0" 
          [selectionDisabled]="selectionDisabled" 
          (selectedChange)="onSelectedChange($event, i)"
        ></tr>
      </tbody>
      <tbody [class.odd]="odd" *ngIf="options.subRow" c-table-subrow [options]="options" [row]="row"></tbody>
    </ng-container>
  </table>
  <table *ngIf="!tileView && currentGroupByColumns.length > 0">
    <thead>
      <tr>
        <th *ngFor="let groupByColumn of currentGroupByColumns"
            [style.text-align]="options.columns[groupByColumn.originalIndex].align || 'left'"
            [style.max-width]="options.columns[groupByColumn.originalIndex].ellipsisMode === 'ellipsed' ? options.columns[groupByColumn.originalIndex].ellipsisWidth : 'auto'">
          {{ options.columns[groupByColumn.originalIndex].header }}
        </th>
        <th *ngFor="let column of options.aggregation?.columns"
          [style.text-align]="column.align || 'left'"
          [style.max-width]="column.ellipsisMode === 'ellipsed' ? column.ellipsisWidth : 'auto'">
          {{ column.header }}
        </th>
        <th *ngIf="options.routerLink"></th>
      </tr>
    </thead>
    <ng-container *ngFor="let row of aggregatedRows; let odd = odd; let i = index">
      <tbody>
        <tr [class.odd]="odd" c-table-row [options]="options" [columns]="columns" [aggregateColumns]="aggregateColumns" [row]="row"></tr>
      </tbody>
      <tbody [class.odd]="odd" *ngIf="options.subRow" c-table-subrow [options]="options" [row]="row"></tbody>
    </ng-container>
  </table>
  <div class="tile-view" *ngIf="tileView">
    <ng-container *ngFor="let row of rows; let i = index">
      <div
        c-tile
        [options]="options"
        [row]="row"
        [selected]="selected.indexOf(row) >= 0"
        [selectionDisabled]="selectionDisabled"
        (selectedChange)="onSelectedChange($event, i)"
        [selectionMode]="selected.length > 0"
        (mouseenter)="tileHover(i)"
        [class.selection-preview]="selectionPreview.includes(row)"
      ></div>
    </ng-container>
  </div>
</div>

<div class="paginator" *ngIf="totalCount !== null && totalCount > (options.size || defaultSize)">
  <p-paginator [first]="first" [rows]="options.size || defaultSize" [totalRecords]="totalCount" (onPageChange)="paginate($event)"></p-paginator>
</div>

<p-sidebar [(visible)]="showColumnSettingsEdit" (visible)="columnSettingsEdit = $event ? columnSettingsEdit : null" position="right" [appendTo]="'body'">
  <ng-template pTemplate="header">Columns</ng-template>
  <ng-template pTemplate="content">
    <div class="draggable-items">
      <div #columnDragElements *ngFor="let column of chosenColumns; let index = index" draggable="true" class="item selectable-column grab-area"
        (drop)="dragDropListHandler?.drop()"
        (dragstart)="dragDropListHandler?.dragStart($event, index)"
        (dragend)="dragDropListHandler?.dragEnd()"
        (dragover)="dragDropListHandler?.dragOver($event, index)">
        <i class="pi pi-pause grab-icon"></i>{{ options.columns[column.originalIndex].header }}<i *ngIf="options.columns[column.originalIndex].visible !== 'fixed'" class="pi pi-times action" (click)="removeColumn(column)"></i>
      </div>
    </div>
    <h4>Available Columns</h4>
    <div class="input search-box">
      <input #searchColumnsBox type="text" placeholder="Search for column..." (input)="searchColumns(searchColumnsBox.value)" autoFocus />
    </div>

    <div class="draggable-items">
      <div *ngFor="let column of filteredColumns; let index = index"class="item selectable-column">
        {{ options.columns[column.originalIndex].header }}<i class="pi pi-plus action" (click)="addColumn(column)"></i>
      </div>
    </div>

  </ng-template>
  <ng-template pTemplate="footer">
    <p-button label="Save" (onClick)="saveColumnsSettings()"></p-button>
  </ng-template>
</p-sidebar>

<p-sidebar [(visible)]="showFilterSettingsEdit" (visible)="filterSettingsEdit = $event ? filterSettingsEdit : null" position="right" appendTo="body" styleClass="sidebar-wide">
  <ng-template pTemplate="header">Filters</ng-template>
  <ng-template pTemplate="content">

    <div class="input search-box">
      <input #searchFiltersBox type="text" placeholder="Search for column..." (input)="searchFilters(searchFiltersBox.value)" autoFocus />
    </div>

    <div *ngFor="let filter of filteredFilters">
      <div class="input">
        <label>{{filter.header}}</label>
        
        <div class="ig ig-select-input" *ngIf="filterSuggestions[filter.path] as suggestions">
          <select [ngModel]="filter.condition.type" (ngModelChange)="conditionTypeChange(filter, $event)">
            <option *ngFor="let conditionType of filter.conditionTypes" [value]="conditionType">{{ conditionType }}</option>
          </select>
          <input *ngIf="filter.condition | as:'Condition':'WithStringValue' as condition" type="text" [(ngModel)]="condition.value" />
          <p-multiSelect *ngIf="filter.condition | as:'Condition':'WithStringValues' as condition"
            [options]="suggestions"
            [(ngModel)]="condition.values"
            optionLabel="label"
            optionValue="value"
            appendTo="body"
          ></p-multiSelect>
        </div>

        <div class="ig ig-select-input" *ngIf="!filterSuggestions[filter.path]">
          <select [ngModel]="filter.condition.type" (ngModelChange)="conditionTypeChange(filter, $event)">
            <option *ngFor="let conditionType of filter.conditionTypes" [value]="conditionType">{{ conditionType }}</option>
          </select>
          <input *ngIf="filter.condition | as:'Condition':'WithStringValue' as condition" type="text" [(ngModel)]="condition.value" />
          <p-chips *ngIf="filter.condition | as:'Condition':'WithStringValues' as condition"
            [(ngModel)]="condition.values"
            [addOnBlur]="true"
            appendTo="body"
          ></p-chips>
          <input *ngIf="filter.condition | as:'Condition':'WithDateValue' as condition" type="date" [(ngModel)]="condition.value" />
        </div>
      </div>
    </div>

  </ng-template>
  <ng-template pTemplate="footer">
    <p-button label="Save" (onClick)="saveFiltersSettings()"></p-button>
  </ng-template>
</p-sidebar>

<p-sidebar [(visible)]="showGroupByColumnSettingsEdit" (visible)="groupByColumnSettingsEdit = $event ? groupByColumnSettingsEdit : null" position="right" appendTo="body">
  <ng-template pTemplate="header">Group by</ng-template>
  <ng-template pTemplate="content">
    <div class="draggable-items">
      <div #groupByDragElements *ngFor="let groupByColumn of chosenGroupByColumns; let index = index" draggable="true" class="item selectable-column grab-area"
        (drop)="dragDropListHandler?.drop()"
        (dragstart)="dragDropListHandler?.dragStart($event, index)"
        (dragend)="dragDropListHandler?.dragEnd()"
        (dragover)="dragDropListHandler?.dragOver($event, index)">
        <i class="pi pi-pause grab-icon"></i>{{ options.columns[groupByColumn.originalIndex].header }}<i class="pi pi-times action" (click)="removeGroupByColumn(groupByColumn)"></i>
      </div>
    </div>
    <h4>Available Columns</h4>
    <div class="input search-box">
      <input #searchGroupByBox type="text" placeholder="Search for column..." (input)="searchGroupByColumns(searchGroupByBox.value)" autoFocus />
    </div>
    <div class="draggable-items">
      <div *ngFor="let groupByColumn of filteredGroupColumns; let index = index" class="item selectable-column">
        {{ options.columns[groupByColumn.originalIndex].header }}<i class="pi pi-plus action" (click)="addGroupByColumn(groupByColumn)"></i>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button label="Save" (onClick)="saveGroupBySettings()"></p-button>
  </ng-template>
</p-sidebar>
