import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AssetTask, Task, TaskSubtask } from "projects/api/src/api";
import { State } from "../../../components/state-bar/state-bar.component";
import { AgencyService } from "../../../services/agency.service";
import { StateService, SubtaskState } from "../../../services/state.service";
import { TaskService } from "../../../services/task.service";
import { AuthService } from "../../../auth/auth.service";

@Component({
  templateUrl: './task-column.component.html',
  styleUrls: ['./task-column.component.scss']
})
export class TaskColumnComponent implements OnInit, OnChanges {
  @Input() task!: Task
  @Input() field: 'state' | 'assetType' | 'openSubtasks' | 'closedSubtasks' = 'state'

  assetTask: AssetTask | null = null
  stateColor: string = 'black';
  openSubtaskStates: State[] = []
  closedSubtaskStates: State[] = []
  hasOwnOpenSubtask = false

  agencyStates: SubtaskState[] = ['Unassigned', 'ViaContract', 'New', 'InProgress']
  contractStates: SubtaskState[] = ['NotRelevant', 'Unassigned', 'New', 'InProgress']

  constructor(
    private taskService: TaskService,
    private stateService: StateService,
    private agencyService: AgencyService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.calculate()
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.calculate()
  }

  /**
   * if agencySubtask
   * - unassigned (red)
   * - via contract, but unassigned (yellow)
   * assigned, new (light blue)
   * assigned, in progress (dark blue)

   * if contractSubtask
   * - unknown / not relevant, filter out
   * - you are contractor, but unassigned (red)
   * assigned, new (light blue)
   * assigned, in progress (dark blue)
   * @param subtask 
   */
  subtaskState(subtask: TaskSubtask): SubtaskState {
    const agencySubtask = this.task.agency === this.agencyService.currentSelectedAgencyId
    const contractSubtask = subtask.contractor === this.agencyService.currentSelectedAgencyId

    if (agencySubtask) {
      if (!subtask.assignee && subtask.contract) return 'ViaContract'
      if (!subtask.assignee) return 'Unassigned'
      if (subtask.state === 'New') return 'New'
      if (subtask.state === 'InProgress') return 'InProgress'
    } else if (contractSubtask) {
      if (!subtask.assignee) return 'Unassigned'
      if (subtask.state === 'New') return 'New'
      if (subtask.state === 'InProgress') return 'InProgress'
    }

    return 'NotRelevant'
  }

  stateName(state: SubtaskState): string {
    if (state === 'New' || state === 'InProgress') return `Assigned - ${state}`
    if (state === 'NotRelevant') return `Not relevant for your agency`
    return state
  }

  calculate() {
    this.assetTask = this.taskService.isAssetTask(this.task) ? this.task : null

    if (this.field === 'state') {
      this.stateColor = this.stateService.taskStateColors[this.task.state]
    } else if (this.field === 'openSubtasks') {

      const subtasksByState = this.task.openSubtasks.reduce((a, b) => {
        const state = this.subtaskState(b)
        if (!a[state]) a[state] = []
        a[state].push(b)
        return a
      }, {} as {[state in SubtaskState]: TaskSubtask[]})

      const states = this.task.agency === this.agencyService.currentSelectedAgencyId ? this.agencyStates : this.contractStates

      this.openSubtaskStates = states.map(s => ({
        name: this.stateName(s),
        value: (subtasksByState[s] || []).length,
        color: this.stateService.subtaskStateColors[s]
      }))

      this.hasOwnOpenSubtask = !!this.task.openSubtasks.find(s => s.assignee === this.authService.currentAuthUser?._id)
    } else if (this.field === 'closedSubtasks') {
      this.closedSubtaskStates = [{
        name: 'Done',
        value: this.task.subtasksStatistic.done,
        color: this.stateService.subtaskStateColors['Done']
      }]
    }
  }
}