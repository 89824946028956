// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export class Environment {
  production: boolean = false;
  apiBasePath: string = 'http://localhost:8080/v1';
  // apiBasePath: string = 'https://api.dev.imagejet.de/v1';
  cdnBasePath: string = 'https://cdn.dev.imagejet.de';
  // authUrl: string = 'http://localhost:8083';
  authUrl: string = 'https://auth.dev.imagejet.de';
  // socketUrl: string = 'http://localhost:8080';
  socketUrl: string = 'https://api.dev.imagejet.de';
  socketPath: string = '/v1/_ws';
  version: string = ''
}

// export const environment: Environment = {
//   production: false,
//   apiBasePath: 'https://api.dev.imagejet.de/customer/v1'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
