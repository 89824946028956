import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { User } from 'projects/api/src/api';
import { Environment } from 'projects/web/src/environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { AgencyService } from '../../services/agency.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  // isAuthenticated$: Observable<boolean>;
  authUser: Observable<User | null>
  // displayName: string;

  constructor(
    private authService: AuthService,
    private environment: Environment
  ) {
    this.authUser = authService.authUser
    // this.isAuthenticated$ = authService.isAuthenticated$;
    // this.displayName = authService.displayName
  }

  ngOnInit() { }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }

  register() {
    window.location.href = `${this.environment.authUrl}/registration`
  }
}
