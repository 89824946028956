import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BASE_PATH } from 'projects/api/src/api';

import { AppModule } from './app/app.module';
import { Environment } from './environments/environment';

(async () => {

  let selectedEnvironment = new Environment();

  try {
    const result = await fetch('/environment.json');
    selectedEnvironment = {...selectedEnvironment, ...(await result.json())};
  } catch (err) {
    console.warn('Could not fetch environment.json. Using default environment.');
  }

  if (selectedEnvironment.production) {
    enableProdMode();
  }

  platformBrowserDynamic([
    { provide: Environment, useValue: selectedEnvironment },
    { provide: BASE_PATH, useValue: selectedEnvironment.apiBasePath }
  ]).bootstrapModule(AppModule).catch(err => console.error(err));

})();
