import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from "@angular/core"

@Component({
  template: `
    <a *ngIf="!!calculatedRouterLink && !click" [routerLink]="calculatedRouterLink"><i *ngIf="iconClass" [ngClass]="iconClass" style="margin-right: 8px;"></i>{{calculatedValue}}</a>
    <a *ngIf="!calculatedRouterLink && !!click" (click)="click(row)"><i *ngIf="iconClass" [ngClass]="iconClass" style="margin-right: 8px;"></i>{{calculatedValue}}</a>
    <span *ngIf="!calculatedRouterLink && !click"><i *ngIf="iconClass" [ngClass]="iconClass" style="margin-right: 8px;"></i>{{calculatedValue}}</span>
  `
})
export class StringColumnComponent implements OnChanges {
  row: any = {}
  column: string = ''
  click?: (row: any) => void
  routerLink?: (row: any) => string[]
  calculatedRouterLink: string[] | undefined = undefined
  icon?: (row: any) => string
  
  value?: (row: any) => string
  calculatedValue?: string
  iconClass?: string = undefined

  constructor() {}

  ngOnInit() {
    this.calculatedRouterLink = this.routerLink ? this.routerLink(this.row) : undefined
    this.calculateValue()

    this.iconClass = this.icon ? this.icon(this.row) : undefined
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["routerLink"]) {
      this.calculatedRouterLink = this.routerLink ? this.routerLink(this.row) : undefined
    }
    this.calculateValue()
    this.iconClass = this.icon ? this.icon(this.row) : undefined
  }

  calculateValue() {
    if (this.value) {
      this.calculatedValue = this.value(this.row)
    } else {
      this.calculatedValue = this.row[this.column]
    }
  }
}
