import { Component } from "@angular/core"
import { User } from "projects/api/src/api"

@Component({
  template: '<span *ngFor="let user of users; let last = last">{{user.displayName}}<span *ngIf="!last">, </span></span>'
})
export class UserColumnComponent {
  row: any = {}
  column: string = ''

  users: User[] = []

  ngOnInit() {
    const value = this.row[this.column]

    if (value) {
      this.users = Array.isArray(value) ? value : [value]
    }
  }
}
