import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'f',
})
export class FPipe implements PipeTransform {

  constructor(
  ) {}

  transform<T>(func: (...args: any[]) => T, ...args: any[]): T {
    return func(...args)
  }

}
