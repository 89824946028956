import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'conditionPathToName',
  pure: true,
})
export class ConditionPathToNamePipe implements PipeTransform {

  transform(value: string): string {
    let name = '';
    if (value.length > 0) {
      name = value.split(".").reverse()[0];
      // name = name.charAt(0).toUpperCase() + name.slice(1);
    }
    return name;
  }

}
