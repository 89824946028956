import { Component, ElementRef, OnInit, ViewChild } from "@angular/core"
import { Action } from "../table.interfaces"
import { MenuItem } from "primeng/api"



@Component({
  template: `
    <p-menu #menu [model]="items" [popup]="true"></p-menu>
    <button type="button" (click)="menu.toggle($event)"><i class="pi pi-ellipsis-v"></i></button>
  `,
  styles: [
    `
    :host { display: block; text-align: right; }
    button { width: 40px; padding: 2px 2px 4px; background-color: transparent; &:hover { background-color: var(--background-dark);} }
    `
  ]
})
export class DropdownColumnComponent implements OnInit {
  row: any = {}
  actions: Action<any>[] = []
  loading = false
  items: MenuItem[] = [];

  ngOnInit(): void {
    for (const action of this.actions) {
      const menuitem: MenuItem = {
        label: action.label,
        icon: action.icon,
        command: async () => {
          await action.action(this.row);
        }
      }
      this.items.push(menuitem);
    }
  }
}
