import { Pipe, PipeTransform } from '@angular/core';
import { Observable, combineLatest, map, of, startWith } from 'rxjs';
import { JobService } from '../services/job.service';
import { Job } from 'projects/api/src/api';

@Pipe({
  name: 'job'
})
export class JobPipe implements PipeTransform {

  constructor(private jobService: JobService) {}

  transform(job: string | undefined | Job, ...args: unknown[]): Observable<{job?: Job, percentage?: number}> {
    const id = typeof job === 'string' ? job : job?._id

    if (!id) return of({})

    return combineLatest([
      typeof job === 'string' ? this.jobService.getJob(job).then(([j]) => j) : Promise.resolve(job),
      this.jobService.register(id).pipe(startWith(null)),
      this.jobService.progress(id).pipe(startWith(null)),
    ]).pipe(map(([current, latest, progress]) => {
      return {
        job: latest || current || undefined,
        percentage: progress?.jobProgress || undefined,
      }
    }))
  }

}
