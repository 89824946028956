import { Component, OnInit } from '@angular/core';
import { AndCondition, EqualsCondition, AssetTask, Subtask, Task, AssetSubtask } from 'projects/api/src/api';
import { Breadcrumb } from '../../../components/header/header.component';
import { TableOptions, TableQuery } from '../../../components/table/table.interfaces';
import { TaskService } from '../../../services/task.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-subtasks',
  templateUrl: './subtasks.component.html',
  styleUrls: ['./subtasks.component.scss']
})
export class SubtasksComponent implements OnInit {
  private readonly subtaskStates: string[] = ['New', 'InProgress', 'Done'];
  private readonly subtaskTypes: string[] = ['CreateAssetVersion', 'CreateVariantSet', 'CreateVariantValue', 'CreateCamera', 'Manual'];

  subtasksTableOptions: TableOptions<Subtask> | null = null;
  breadcrumbs?: Breadcrumb[] = [];

  constructor(private taskService: TaskService) {
  }

  async ngOnInit(): Promise<void> {
    this.breadcrumbs = [
      { title: 'Tasks', routerLink: ['/tasks'] },
      { title: 'Subtasks' }
    ];

    this.subtasksTableOptions = {
      search: false,
      columns: [
        {
          name: 'assetName',
          header: 'Asset Name',
          visible: true,
          value: (subtask) => (subtask as AssetSubtask).asset$?.name!
        },
        {
          name: 'assetDisplayName',
          header: 'Asset Display Name',
          visible: true,
          value: (subtask) => (subtask as AssetSubtask).asset$?.displayName!
        },
        {
          name: 'assetType',
          header: 'Asset Type',
          visible: true,
          value: (subtask) => (subtask as AssetSubtask).asset$?.assetType!
        },
        {
          name: 'numberOfOrderLines',
          header: 'Affected Orderlines',
          visible: true,
          format: 'number',
          align: 'right'
        },
        {
          name: 'subtask',
          header: 'Subtask',
          visible: true,
          format: 'subtask',
          attr: 'Subtask'
        },
        {
          name: 'type',
          header: 'Type',
          visible: false,
          filter: {
            path: 'type',
            values: [...this.subtaskTypes].map(type => ({
              label: type,
              value: type
            })),
            conditionTypes: ['In', 'NotIn']
          }
        },
        {
          name: 'state',
          header: 'State',
          visible: true,
          filter: {
            path: 'state',
            values: [...this.subtaskStates].map(c => ({
              label: c,
              value: c,
            })),
            conditionTypes: ['In', 'NotIn']
          },
        },
        {
          name: 'task',
          header: 'Task',
          visible: true,
          value: () => 'Open Task',
          routerLink: (subtask) => ['/tasks', subtask.task!],
        }
      ],
      defaultSort: 'numberOfOrderLines',
      defaultSortDirection: 'desc'
    };
  }

  querySubtasks(query: TableQuery<Subtask>) {
    query.result = this.taskService.getSubtasks({
      ...query.query,
      lookups: 'asset'
    });
  }
}
