<div *ngIf="!loading && ownSubtask">

  <form [formGroup]="ownSubtaskForm" (submit)="submitOwnTask()" (change)="ownTaskFormChanged()">
    <label>
      <input type="radio" formControlName="mode" value="ownSubtask" />
      Own Subtask
    </label>
  
    <p>This subtask will be done by {{task.agency$?.displayName}}.</p>
    
    <div class="input">
      <label for="assignee">Select User</label>
      <select name="assignee" formControlName="assignee">
        <option value=""></option>
        <option *ngFor="let user of users" [value]="user._id">{{user.displayName}}</option>
      </select>
    </div>
  
    <label>
      <input type="radio" formControlName="mode" value="contractSubtask" />
      Via Contract
    </label>
  
    <p>This subtask will be done by another agency via contract.</p>
  
    <div class="input">
      <label for="user">Select Contract</label>
      <select name="contract" formControlName="contract">
        <option value=""></option>
        <option *ngFor="let contract of contracts" [value]="contract._id">{{contract.name}}</option>
      </select>
    </div>

    <button type="submit" [disabled]="!ownSubtaskForm.valid || ownSubtaskForm.disabled">Assign</button>

  </form>

</div>

<div *ngIf="!loading && contractSubtask">
  <form [formGroup]="contractSubtaskForm" (submit)="submitContractTask()">
    <p>This subtask should be done by your agency via contract.</p>
    <div class="input">
      <label for="assignee">Select User</label>
      <select name="assignee" formControlName="assignee">
        <option value=""></option>
        <option *ngFor="let user of users" [value]="user._id">{{user.displayName}}</option>
      </select>
    </div>
    
    <button type="submit" [disabled]="!contractSubtaskForm.valid || ownSubtaskForm.disabled">Assign</button>
  </form>
</div>

<div *ngIf="loading" class="loading">
  <i class="pi pi-spin pi-spinner"></i>
</div>