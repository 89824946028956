import { AfterViewInit, Component, Directive, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Tag } from 'projects/api/src/api';

export interface Breadcrumb {
  title: string,
  routerLink?: string[]
}

@Component({
  selector: 'c-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit  {

  @ViewChild('addTagInput', {static: false}) addTagInput?: ElementRef

  @Input() header: string = ''
  @Input() subheader?: string = ''
  @Input() breadcrumbs?: Breadcrumb[]
  @Input() tags?: string[]

  @Output() tagsChange = new EventEmitter<string[]>();

  showAddSign: boolean = true
  addTagValue: string = ''
  addedTags: string[] = []
  showDeleteButton: boolean = false

  constructor() { }

  ngOnInit(): void {
  }

  showAddTagInput() {
    this.showAddSign = false;
    this.addTagValue = ''

    setTimeout(() => this.addTagInput?.nativeElement.focus())
  }

  blurAddTagInput() {
    this.showAddSign = true

    if (this.addTagValue && this.tags) {
      this.tags.push(this.addTagValue.trim())
      this.tagsChange.emit(this.tags);
    }
  }

  deleteTag(index: number) {
    if (index > -1 && this.tags) {
      this.tags.splice(index, 1);
      this.tagsChange.emit(this.tags);
    }
  }

  keyupEnterAddTagInput() {
    this.blurAddTagInput()
  }
}
