import { Component } from "@angular/core"
import { Action } from "../table.interfaces"



@Component({
  template: `
    <button *ngFor="let action of actions; let index=index" (click)="execute(action)" class="small">{{action.label}}</button>
  `,
  styles: [
    `:host {display: block; text-align: right;}`
  ]
})
export class ActionsColumnComponent {
  row: any = {}
  actions: Action<any>[] = []
  loading = false

  async execute(action: Action<any>) {
    this.loading = true

    try {
      await action.action(this.row)
    } catch (err) {
      console.error(err)
    }

    this.loading = false
  }
}
