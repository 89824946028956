import { Component, Input } from '@angular/core';
import { Asset } from 'projects/api/src/api';
import { AssetService } from '../../../services/asset.service';
import { grids } from './asset-tilesview.grids';


@Component({
  selector: 'c-asset-tilesview',
  templateUrl: './asset-tilesview.component.html',
  styleUrls: ['./asset-tilesview.component.scss']
})
export class AssetTilesviewComponent {

  searchTerm: string = ''
  widths: string[] = []

  @Input() assets: Asset[] = []


  constructor(
    private assetService: AssetService,
  ) {}

  ngOnInit() {
    this.query()
  }

  trackById(index: number, asset: Asset) {
    return asset._id
  }

  async query() {
    const [assets] = await this.assetService.query({
      searchTerm: this.searchTerm,
      lookups: 'agency'
    })

    const grid = grids["1600"]

    this.assets = assets?.items || []
    this.widths = this.assets.map((asset, index) => `${grid[index % grid.length]}%`)

  }

}
